import React, { useState } from "react";
import * as moment from "moment";
import { useOrganization } from "../../context/OrganizationContext";

export function LoadingAllDownloadBtn({ reports }) {
  const { downloadReports } = useOrganization();
  const [loading, setLoading] = useState(false);

  const icon = loading ? (
    <i className="mdi mdi-loading mdi-spin label-icon align-middle"></i>
  ) : (
    <i className="mdi mdi-download-box-outline label-icon align-middle"></i>
  );

  async function handleClick() {
    if (loading) return;
    if (reports.length === 0) return;
    setLoading(true);

    const reports_dates = reports
      .map((report) => moment(report["Purchase Date"]).format("MM-DD-YYYY"))
      .sort((a, b) => moment(a).format("X") - moment(b).format("X"));

    const start_purchase_date = reports_dates[0];
    const end_purchase_date = reports_dates[reports_dates.length - 1];

    let { zip_data } = await downloadReports(
      reports.map((report) => report.id),
    );
    zip_data = window.atob(zip_data);
    let buffer = new ArrayBuffer(zip_data.length),
      view = new Uint8Array(buffer);
    for (let i = 0; i < zip_data.length; i++) {
      view[i] = zip_data.charCodeAt(i);
    }

    let downloadFileName = "";
    if (start_purchase_date === end_purchase_date) {
      downloadFileName = `${start_purchase_date}`;
    } else {
      downloadFileName = `${start_purchase_date} to ${end_purchase_date}`;
    }

    downloadFileName += " Crash Reports.zip";
    window.saveAs(new Blob([view], { type: "octet/stream" }), downloadFileName);
    setLoading(false);
  }

  return (
    <button
      className="cart-btn btn btn-sm btn-primary btn-label"
      onClick={handleClick}
    >
      {icon}
      <div className="cart-btn-txt">Download All Reports</div>
    </button>
  );
}

export function LoadingDownloadBtn({ reportId, stateAbb, crashDate }) {
  const { downloadReport } = useOrganization();
  const [loading, setLoading] = useState(false);

  const icon = loading ? (
    <i className="mdi mdi-loading mdi-spin fs-20 align-middle"></i>
  ) : (
    <i className="mdi mdi-download-box-outline fs-20 align-middle"></i>
  );

  async function handleClick() {
    if (loading) return;
    setLoading(true);
    let { report_number, pdf_data } = await downloadReport(reportId);
    pdf_data = window.atob(pdf_data);
    let buffer = new ArrayBuffer(pdf_data.length),
      view = new Uint8Array(buffer);
    for (let i = 0; i < pdf_data.length; i++) {
      view[i] = pdf_data.charCodeAt(i);
    }
    const downloadFileName = `${stateAbb}_${report_number}_${crashDate}.pdf`;
    window.saveAs(
      new Blob([view], { type: "application/pdf" }),
      downloadFileName,
    );
    setLoading(false);
  }

  return (
    <button
      onClick={handleClick}
      className="btn btn-light btn-icon"
      role="button"
    >
      {icon}
    </button>
  );
}
