import React, { useEffect, useState, useRef, useCallback } from "react";
import { ColumnFilter } from "../../common";
import { Progress, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useAuth } from "../../context/AuthContext";
import { useOrganization } from "../../context/OrganizationContext";
import * as XLSX from "xlsx";
import * as api from "../../api";
import { toHHMMSS, getConditionalTitleCase } from "../../util";

function titleCase(txt) {
  return txt.charAt(0).toUpperCase() + txt.slice(1);
}

function TempTable(props) {
  const defaultValues = {
    sort: {
      onClick: () => {},
      className: `bx bx-sort-a-z`,
    },
    filter: { options: [] },
  };
  const tempColumnHeaderData = [
    {
      key: "Address",
      ...defaultValues,
    },
    {
      key: "Date of Accident",
      ...defaultValues,
    },
    {
      key: "Insurance Company",
      ...defaultValues,
    },
    {
      key: "Number of Occupants",
      ...defaultValues,
    },
    {
      key: "Occupant DOB",
      ...defaultValues,
    },
    {
      key: "Occupant Name",
      ...defaultValues,
    },
    {
      key: "Policy Number",
      ...defaultValues,
    },
    {
      key: "Report Number",
      ...defaultValues,
    },
    {
      key: "State, City, Zip",
      ...defaultValues,
    },
    {
      key: "Unit Number",
      ...defaultValues,
    },
    {
      key: "Units Involved",
      ...defaultValues,
    },
  ];
  return (
    <table id="report-table" className="table table-bordered table-nowrap mb-0">
      <thead>
        <tr>
          {tempColumnHeaderData.map((e, i) => (
            <th key={i} scope="col">
              <div>
                <span>{e.key}</span>
                <span className="filters-and-sort-icons">
                  <i onClick={() => {}}></i>
                </span>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array(20).keys()].map((key) => (
          <tr key={key}>
            <td>Lorem ipsum</td>
            <td>Lorem ipsum</td>
            <td>Lorem ipsum</td>
            <td>Lorem ipsum</td>
            <td>Lorem ipsum</td>
            <td>Lorem ipsum</td>
            <td>Lorem ipsum</td>
            <td>Lorem ipsum</td>
            <td>Lorem ipsum</td>
            <td>Lorem ipsum</td>
            <td>Lorem ipsum</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default function OcrTable({ selectedState, selectedJurisdiction, selectedWebsite, resetSelections, ocrPrice, ocrLimit }) {
  const { getOcrReportData, updateOrganizationData } = useOrganization();
  const { userIsAdmin, userOrg, getUserToken, userNamespace } = useAuth();

  const [allTableData, setAllTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [initialFilterAt, setInitialFilterAt] = useState({});
  const [initialSortAt, setInitialSortAt] = useState({});
  const [filterAt, setFilterAt] = useState(initialFilterAt);
  const [sortAt, setSortAt] = useState(initialSortAt);
  const [filterOrderKey, setFilterOrderKey] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [timeLeft, setTimeLeft] = useState("");

  const [columnNames, setColumnNames] = useState([]);
  const [columnNamesDisplay, setColumnNamesDisplay] = useState([]);

  const [socketConnection, setSocketConnection] = useState(null);

  const [fileState, setFileState] = useState({
    files: [],
    invalidFile: false,
  });

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({});

  const [ocrModalOpen, setOcrModalOpen] = useState(false);
  const toggleOcrModal = () => setOcrModalOpen(!ocrModalOpen);

  const fileInputRef = useRef();

  const getSortClass = (colKey) => ["bx-sort-a-z", "bx-sort-z-a", "bx-sort-z-a"][sortAt[colKey]];
  const updateSortAt = (colKey) => setSortAt({ ...initialSortAt, [colKey]: (sortAt[colKey] + 1) % 3 });

  const getFilterOptions = useCallback(
    (colKey) => {
      let options = [...allTableData];

      const fIndex = filterOrderKey.findIndex((e) => e === colKey);
      if (fIndex !== -1) {
        for (let i = 1; i <= fIndex; i++) {
          const preKey = filterOrderKey[i - 1];
          const preOptions = filterAt[preKey].map((e) => e.value);
          options = options.filter((e) => preOptions.includes(e[preKey]));
        }
      } else {
        options = [...filteredTableData];
      }

      const toptions = options.reduce((acc, curr) => {
        acc.add(curr[colKey]);
        return acc;
      }, new Set());

      const result = [...toptions.values()].map((e) => ({ value: e, label: e }));

      return result;
    },
    [allTableData, filterAt, filterOrderKey, filteredTableData]
  );

  const [columnHeaderData, setColumnHeaderData] = useState([]);

  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);

  async function resetTable() {
    setAllTableData([]);
    setFilteredTableData([]);
    setFilterAt(initialFilterAt);
    setSortAt(initialSortAt);
    setFilterOrderKey([]);
    setFileState({ files: [], invalidFile: false });

    setShow(false);
    setLoading(false);

    try {
      fileInputRef.current.value = "";
    } catch (e) {
      return;
    }
  }

  useEffect(() => {
    resetTable();
  }, [selectedState, selectedJurisdiction, selectedWebsite]);

  function connectSocket() {
    if (userNamespace) {
      if (socketConnection) {
        socketConnection.disconnect();
      }

      const socket = window.io.connect(api.DOMAIN, {
        // reconnectionDelayMax: 10000,
        timeout: 20000,
        autoConnect: true,
      });

      socket.on(userNamespace, function (message) {
        let { estimated_time, ocr_report_progress } = message;
        let progress = ocr_report_progress * 100 * 1.33333333333333;
        progress = progress >= 100 ? 99 : progress;
        setProgressValue(progress.toFixed(2));
        const est_time_str = toHHMMSS(estimated_time);
        setTimeLeft(est_time_str);
      });

      setSocketConnection(socket);
    }
  }

  useEffect(() => {
    connectSocket();
  }, [userNamespace]);

  useEffect(() => {
    return () => {
      if (socketConnection) {
        socketConnection.disconnect();
      }
    };
  }, []);

  async function checkPermissions(juryOrWebsite) {
    if (userIsAdmin) return true;
    const [permissionGranted, status] = await api.checkOcrPermissions(await getUserToken(), { org_id: userOrg, juryOrWebsite });
    return permissionGranted;
  }

  async function generateOcrReport(state, juryOrWebsite) {
    const permission = await checkPermissions(juryOrWebsite);
    if (!permission) {
      if (selectedWebsite) {
        setModalMessage({
          class: "alert-danger",
          message: `Your organization does not have permission to generate OCR reports using ${getConditionalTitleCase(selectedWebsite)}.`,
        });
      } else {
        setModalMessage({
          class: "alert-danger",
          message: `Your organization does not have permission to generate OCR reports from jurisdiction ${getConditionalTitleCase(selectedJurisdiction)}, state ${selectedState}.`,
        });
      }
      resetSelections();

      toggleSuccessModal();
      return;
    }

    setLoading(true);
    setProgressValue(0);

    const zip_data = await getZippedDate();
    const reportData = await getOcrReportData(state, juryOrWebsite, zip_data);

    if (reportData === "Invalid PDF") {
      setModalMessage({
        class: "alert-danger",
        message: `Error generating OCR report. Please check the PDF files and try again.`,
      });
      resetTable();
      toggleSuccessModal();
      return;
    }

    if (reportData === "Insufficient balance") {
      setModalMessage({
        class: "alert-danger",
        message: `Insufficient balance. Please contact your organization's admin to recharge your account.`,
      });
      resetTable();
      toggleSuccessModal();
      return;
    }

    let { organization_data, ocr_data, error_results } = reportData;

    await new Promise((resolve) => setTimeout(resolve, 500));

    if (error_results.length) {
      const error_msg = error_results.map(([file, error]) => `${file}`).join("<br>");

      const modalMsg = {
        class: "alert-danger",
        message: `
        <div>
          <div>Error generating OCR report for the following files:</div>
            <div>${error_msg}</div>
          <div>Please check the files and try again.</div>

          <i class="text-muted">You won't be charged for the files that failed to generate OCR reports.</i>
        </div>
        `,
      };

      setModalMessage(modalMsg);
      toggleSuccessModal();

      if (ocr_data.length === 0) {
        resetTable();
        return;
      }
    }

    const column_names = Object.keys(ocr_data[0]);
    const column_names_display = column_names.map((e) => getConditionalTitleCase(e).replace(/_/g, " "));
    setColumnNames(column_names);
    setColumnNamesDisplay(column_names_display);

    const newFilterAt = column_names_display.reduce((acc, cur) => {
      acc[cur] = [];
      return acc;
    }, {});

    const newSortAt = column_names_display.reduce((acc, cur) => {
      acc[cur] = 2;
      return acc;
    }, {});

    setInitialFilterAt(newFilterAt);
    setInitialSortAt(newSortAt);
    setFilterAt(newFilterAt);
    setSortAt(newSortAt);

    ocr_data = ocr_data.map((e) => {
      const new_obj = {};
      for (let i = 0; i < column_names.length; i++) {
        let value = e[column_names[i]];
        if (value === null || value === undefined) value = "";
        new_obj[column_names_display[i]] = value;
      }
      return new_obj;
    });

    setAllTableData(ocr_data);
    setLoading(false);
    setShow(true);
    updateOrganizationData({ data: organization_data });
  }

  useEffect(() => {
    if (Object.keys(filterAt).length === 0) return;
    setColumnHeaderData([
      ...columnNamesDisplay.map((e, i) => {
        return {
          key: e,
          sort: {
            onClick: () => updateSortAt(e),
            className: `bx ${getSortClass(e)}`,
          },
          filter: { options: getFilterOptions(e) },
        };
      }),
    ]);
  }, [columnNamesDisplay, filterAt, sortAt, allTableData, filterOrderKey, filteredTableData]);

  // allTableData, filterAt, filterOrderKey, filteredTableData

  async function getZippedDate() {
    const new_zip = new window.JSZip();

    for (let i = 0; i < fileState.files.length; i++) {
      const file = fileState.files[i];
      const file_name = file.name;
      const file_ext = file_name.split(".").pop();
      const file_name_without_ext = file_name.split(".").slice(0, -1).join(".");
      // const file_name_with_ext = file_name_without_ext + "_" + i + "." + file_ext;
      const file_name_with_ext = file_name_without_ext + "." + file_ext;
      new_zip.file(file_name_with_ext, file);
    }

    const zip_data = await new_zip.generateAsync({ type: "base64" });
    return zip_data;
  }

  function onSubmit(e) {
    if (fileState.files.length === 0 || fileState.invalidFile) return;

    if (fileState.files.length > ocrLimit) {
      setModalMessage({
        class: "alert-danger",
        message: `You can only upload a maximum of ${ocrLimit} files at a time.`,
      });
      toggleSuccessModal();
      return;
    }

    toggleOcrModal();
  }

  function getSortingKey() {
    for (let [key, value] of Object.entries(sortAt)) {
      if (value === 0) {
        return [key, "asc"];
      } else if (value === 1) {
        return [key, "desc"];
      }
    }

    return ["", ""];
  }

  function sortData(data) {
    const [sortKey, order] = getSortingKey();
    data.sort((a, b) => new Date(a[sortKey]) - new Date(b[sortKey]));
    if (order === "asc") {
      data.sort((a, b) => a[sortKey]?.toString().localeCompare(b[sortKey]?.toString()));
    } else if (order === "desc") {
      data.sort((b, a) => a[sortKey]?.toString().localeCompare(b[sortKey]?.toString()));
    }
    return data;
  }

  function filterData(data) {
    for (let [key, value] of Object.entries(filterAt)) {
      const values = value.map((e) => e.value);
      if (values.length === 0) {
        if (filterOrderKey.includes(key)) setFilterOrderKey(filterOrderKey.filter((e) => e !== key));
        continue;
      }

      if (!filterOrderKey.includes(key)) setFilterOrderKey([...filterOrderKey, key]);

      data = data.filter((e) => values.includes(e[key]));
    }

    return data;
  }

  useEffect(() => {
    const data = sortData(filterData([...allTableData]));
    setFilteredTableData(sortData(filterData(data)));
  }, [sortAt, filterAt, allTableData]);

  function onExport() {
    if (filteredTableData.length === 0) return;
    const data = [...filteredTableData];

    const initialLengths = {};

    for (let i = 0; i < columnNames.length; i++) {
      initialLengths[columnNames[i]] = columnNamesDisplay[i].length;
    }

    const colLengths = data.reduce((acc, item) => {
      for (let i = 0; i < columnNames.length; i++) {
        const new_len = item[columnNamesDisplay[i]].toString().length;
        if (new_len > acc[columnNames[i]]) acc[columnNames[i]] = new_len;
      }
      return acc;
    }, initialLengths);

    const ws = XLSX.utils.json_to_sheet(data);
    // ws["!cols"] = [
    //   { wch: colLengths.address * 1.4 },
    //   { wch: colLengths.date_of_accident * 1.4 },
    //   { wch: colLengths.insurance_co * 1.4 },
    //   { wch: colLengths.number_of_occupants * 1.4 },
    //   { wch: colLengths.occupant_dob * 1.4 },
    //   { wch: colLengths.occupant_name * 1.4 },
    //   { wch: colLengths.policy_no * 1.4 },
    //   { wch: colLengths.report_number * 1.4 },
    //   { wch: colLengths.state_city_zip * 1.4 },
    //   { wch: colLengths.unit_number * 1.4 },
    //   { wch: colLengths.units_involved * 1.4 },
    // ];
    ws["!cols"] = columnNames.map((e) => ({ wch: colLengths[e] * 1.4 }));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "OCR-Report.xlsx");
  }

  function fileValidator(files) {
    let valid = true;

    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== "application/pdf") {
        valid = false;
        break;
      }
    }

    return valid;
  }

  function handleFileChange(e) {
    const files = e.target.files;

    const cancel = !files.length;
    if (cancel) return;

    const validFile = fileValidator(files);

    if (!validFile) {
      e.target.value = null;
      setFileState({ ...fileState, invalidFile: true });
      alert("Invalid file type. Please upload only PDF files.");
      return;
    }

    const newFiles = [];

    for (let i = 0; i < files.length; i++) {
      newFiles.push(files[i]);
    }

    setFileState({ files: newFiles, invalidFile: false });
  }

  function submitBtnDisabled() {
    if (fileState.files.length === 0) return true;
    if (selectedWebsite !== "") return false;
    return selectedState === "" || selectedJurisdiction === "";
  }

  function runOCR() {
    connectSocket();
    if (selectedWebsite) {
      generateOcrReport(null, selectedWebsite);
      return;
    }

    if (selectedState && selectedJurisdiction) {
      generateOcrReport(selectedState, selectedJurisdiction);
    }

    toggleOcrModal();
  }

  return (
    <>
      <Modal isOpen={ocrModalOpen} toggle={toggleOcrModal} centered size="md">
        <div className="modal-content border-0">
          <div className="modal-header p-3 bg-soft-info">
            <h5 className="modal-title" id="myModalLabel">
              OCR Run Details
            </h5>
          </div>
          <ModalBody style={{ maxHeight: "60vh", overflowY: "auto", padding: "1rem" }}>
            <div className="table-responsive table-card">
              <table className="table table-borderless table-hover table-nowrap align-middle mb-0">
                <thead className="table-light">
                  <tr className="text-muted">
                    <th>Price per file</th>
                    <th>Files to Process</th>
                    <th>Total Run Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>${ocrPrice}</td>
                    <td>{fileState.files.length}</td>
                    <td>${ocrPrice * fileState.files.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ModalBody>
          <ModalFooter style={{ marginTop: "2rem" }}>
            <button className="btn btn-secondary" onClick={toggleOcrModal}>
              Close
            </button>

            <button className="btn btn-primary" onClick={runOCR} disabled={submitBtnDisabled()}>
              Run
            </button>
          </ModalFooter>
        </div>
      </Modal>

      <div className="d-flex justify-content-between align-items-center pt-4 pb-2 gap-4" style={{ visibility: show ? "visible" : "hidden" }}>
        <div className="fs-14" style={{ fontWeight: "500" }}>
          {/* Total Reports: {Array.from(new Set(filteredTableData.map((e) => e["Report Number"]))).length} */}
        </div>

        <button className="cart-btn btn btn-sm btn-primary btn-label" onClick={onExport}>
          <i className={`bx bxs-file-export label-icon align-middle`}></i>
          <div className="cart-btn-txt">Export to Excel</div>
        </button>
      </div>
      <div id="report-table-container" data-show={show}>
        {!loading && (
          <div id="ocrTableForm">
            <Input
              key={1}
              type="file"
              id="pdfFileInput"
              name="pdfFileInput"
              label={`${fileState.files?.length} files selected` || "Choose PDF files"}
              aria-label={fileState.files?.length ? fileState.files[0] || "Choose PDF files" : "Choose PDF files"}
              aria-labelledby={fileState.files?.length ? fileState.files[0] || "Choose PDF files" : "Choose PDF files"}
              innerRef={fileInputRef}
              multiple
              onChange={handleFileChange}
              invalid={fileState.invalidFile}
            />

            {ocrLimit ? (
              <i className="w-100" style={{ margin: "-6px 0 0 8px" }}>
                <div className="text-muted">File Limit: {ocrLimit}</div>
              </i>
            ) : null}
            <button onClick={onSubmit} className="btn btn-primary btn-label" disabled={submitBtnDisabled()}>
              <i className="bx bx-upload fs-19 label-icon align-middle"></i>
              <span>Submit</span>
            </button>
          </div>
        )}
        {loading && (
          <div id="reports-table-progress">
            {timeLeft !== "" ? (
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  Processing {fileState.files?.length} files (Estimated time: {timeLeft})
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center" style={{ gap: "0.6rem" }}>
                <div
                  style={{
                    width: "18px",
                    height: "18px",
                    backgroundImage: `url("https://icon-library.com/images/loading-icon-transparent-background/loading-icon-transparent-background-12.jpg")`,
                    backgroundColor: "transparent",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div>Uploading {fileState.files?.length} files</div>
              </div>
            )}
            <Progress value={progressValue} />
          </div>
        )}
        <div className="table-responsive">
          {!show && <TempTable />}
          {show && (
            <table id="report-table" className="table table-bordered table-nowrap mb-0">
              <thead>
                <tr>
                  {columnHeaderData.map((e, i) => (
                    <th key={i} scope="col">
                      <div>
                        <span>{e.key}</span>
                        <span className="filters-and-sort-icons">
                          <i onClick={e.sort.onClick} className={e.sort.className}></i>
                          <ColumnFilter total={columnHeaderData.length} index={i} colKey={e.key} options={e.filter.options} setFilterAt={setFilterAt} filterAt={filterAt} />
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredTableData.map((data, index) => {
                  return (
                    <tr key={index}>
                      {columnNamesDisplay.map((e, i) => {
                        return <td key={i}>{data[e]}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <Modal isOpen={successModalOpen} toggle={toggleSuccessModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0">
            <div className={`alert ${modalMessage.class} alert-dismissible fade show m-0`} role="alert">
              <div dangerouslySetInnerHTML={{ __html: modalMessage.message }} />
              <button type="button" className="btn-close" onClick={toggleSuccessModal} aria-label="Close"></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}
