import axios from "axios";
import * as moment from "moment";

let domain = window.location.origin;

if (process.env.NODE_ENV === "development") {
  domain = "http://localhost:5000";
}

export const DOMAIN = domain;

const API_URL = DOMAIN + "/api/";

const axiosInstance = axios.create({ baseURL: API_URL });

const getHeaders = (token) => ({ Authorization: `Bearer ${token}` });

export async function getOrganizationalStats(token, data) {
  const r = await axiosInstance.post("get-organizational-stats", data, {
    headers: getHeaders(token),
  });
  if (r.status === 200) {
    return [r.data.data, true];
  }
  return [[], false];
}

export async function getOcrReportData(token, data) {
  const r = await axiosInstance.post("generate-ocr-report", data, {
    headers: getHeaders(token),
  });
  if (r.status === 200) {
    return [r.data.data, 200];
  }
}

export async function getOcrPageStateJury(token) {
  const r = await axiosInstance.get("get-ocr-jurisdictions", {
    headers: getHeaders(token),
  });
  if (r.status === 200) {
    return [r.data.data, 200];
  }
}

export async function getOcrData(token) {
  const r = await axiosInstance.get("get-ocr-data", {
    headers: getHeaders(token),
  });
  if (r.status === 200) {
    return [r.data.data, 200];
  }
}

export async function updateOcrData(token, data) {
  const r = await axiosInstance.post("update-ocr-data", data, {
    headers: getHeaders(token),
  });
  if (r.status === 200) {
    return [r.data.data, 200];
  }
}

export async function toggleOcrPermission(token, data) {
  try {
    const r = await axiosInstance.post("toggle-ocr-permissions", data, {
      headers: getHeaders(token),
    });
    if (r.status === 200) return [r.data.data, true];
  } catch (e) {
    // console.log(e)
  }

  return ["Server Error", false];
}

export async function checkOcrPermissions(token, data) {
  try {
    const r = await axiosInstance.post("check-ocr-permissions", data, {
      headers: getHeaders(token),
    });
    if (r.status === 200) return [r.data.data, true];
  } catch (e) {
    // console.log(e)
  }

  return [false, false];
}

export async function getNamespace() {
  const r = await axiosInstance.get("get-namespace");

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get namespace");

  return ["Server Error", false];
}

export async function creditCheckout(token, data) {
  const r = await axiosInstance.post("credit-checkout", data, {
    headers: getHeaders(token),
  });
  if (r.status === 200) {
    return r.data.redirect;
  }
}

export async function checkoutSession(token, data) {
  const r = await axiosInstance.post("checkout-session", data, {
    headers: getHeaders(token),
  });
  if (r.status === 200) {
    return r.data.session;
  }
}

export async function getOrganizations(token) {
  const r = await axiosInstance.get("get-organizations", {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get all report log");
  return [[], false];
}

export async function addOrganization(token, data) {
  const r = await axiosInstance.post("add-organization", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to add organization");
  return [[], false];
}

export async function updateUser(token, data) {
  const r = await axiosInstance.post("update-user", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to update user");
  return [[], false];
}

export async function deleteUser(token, data) {
  const r = await axiosInstance.post("delete-user", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to delete user");
  return [[], false];
}

export async function addMember(token, data) {
  const r = await axiosInstance.post("add-organization-member", data, {
    headers: getHeaders(token),
  });
  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to add member");
  return [[], false];
}

export async function purchaseReports(token, data) {
  const r = await axiosInstance.post("purchase-reports", data, {
    headers: getHeaders(token),
  });
  return [r.data.data, r.status];
}

export async function getUserOrganization(token) {
  const r = await axiosInstance.get("get-user-organizations", {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get user organization");
  return [[], false];
}

export async function getStatesData(token) {
  const r = await axiosInstance.get("get-states-and-jurisdictions", {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get states data");
  return [[], false];
}

export async function getStateLimits(token) {
  const r = await axiosInstance.get("get-state-limits", {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get states limits");
  return [[], false];
}

export async function setStateLimits(token, data) {
  const r = await axiosInstance.post("set-state-limit", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return ["ok", true];
  }

  console.log(r.status, "failed to get states data");
  return ["error", false];
}

export async function toggleState(token, data) {
  const r = await axiosInstance.post("toggle-state", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return ["ok", true];
  }

  console.log(r.status, "failed to get states data");
  return ["error", false];
}

export async function toggleJurisdiction(token, data) {
  const r = await axiosInstance.post("toggle-jurisdiction", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return ["ok", true];
  }

  console.log(r.status, "failed to get states data");
  return ["error", false];
}

export async function addSubtractCredit(token, data) {
  const r = await axiosInstance.post("add-subtract-credit", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get states data");

  return [[], false];
}

export async function getUserCreditLogData(token, data) {
  const r = await axiosInstance.post("user-credit-log-data", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get states data");

  return [[], false];
}

export async function getUserOcrLogData(token, data) {
  const r = await axiosInstance.post("user-ocr-log-data", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get states data");

  return [[], false];
}

export async function getReportData(token) {
  const r = await axiosInstance.get("report-data", {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get topup data");

  return [[], false];
}

export async function getTopupData(token) {
  const r = await axiosInstance.get("topup-data", {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get topup data");

  return [[], false];
}

export async function disabledOrEnableOrganization(token, data) {
  const r = await axiosInstance.post("disable-enable-organization", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to disable/enable organization");

  return [[], false];
}

export async function verifyOrganization(token, data) {
  const r = await axiosInstance.post("verify-organization", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to verify organization");

  return [{}, false];
}

export async function resetUserPassword(token, data) {
  const r = await axiosInstance.post("reset-password", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to reset user");

  return [false, false];
}

export async function toggleOhioCrashApp(token, data) {
  const r = await axiosInstance.post("toggle-ohio-crash-app", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to reset user");

  return [{ data: [] }, false];
}

export async function toggleNashvilleMailUser(token, data) {
  const r = await axiosInstance.post("toggle-nashville-mail-user", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to reset user");

  return [{ data: [] }, false];
}

export async function getAllReports(dataObj) {
  const { token, data, setProgress } = dataObj;

  const r = await axiosInstance.post("get-reports", data, {
    headers: getHeaders(token),

    onDownloadProgress: function (progressEvent) {
      const totalLength = progressEvent.target.getResponseHeader("content-data-length");
      if (setProgress) setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
    },
  });

  if (r.status === 200) {
    const reports = [];
    for (let row of r.data.data) {
      const { id, report_type, agency_ori, jurisdiction, state, crash_date, number_of_cars, parties_details, trf_price, previously_downloaded, file_size, downloaded } = row;
      reports.push({
        id,
        state,
        agency_ori,
        jurisdiction,
        parties_details,
        "Report Type": report_type,
        "Agency Name": jurisdiction,
        "Crash Date": moment(crash_date).format("MM/DD/YYYY"),
        "Number of Cars Involved": number_of_cars,
        "Car Year": parties_details.vehicles.map(({ description }) => description.year),
        "Car Make": parties_details.vehicles.map(({ description }) => description.make),
        "Car Model": parties_details.vehicles.map(({ description }) => description.model),
        "Car Plate": parties_details.vehicles.map(({ description }) => description.plate),
        Price: trf_price,
        "Previously Downloaded": previously_downloaded ? "Yes" : "No",
        Size: file_size,
        "Upload Date": "02/31/2020",
        downloaded,
      });
    }

    return reports;
  }

  console.log(r.status, "failed to get all reports");

  return [];
}

export async function getPurchaseHistory(token, data) {
  const r = await axiosInstance.post("get-purchase-history", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed to get purchase history");

  return [{ data: [] }, false];
}

export async function downloadReport(token, data) {
  const r = await axiosInstance.post("download-report", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed download");

  return [{ data: [] }, false];
}

export async function downloadReports(token, data) {
  const r = await axiosInstance.post("download-reports", data, {
    headers: getHeaders(token),
  });

  if (r.status === 200) {
    return [r.data, true];
  }

  console.log(r.status, "failed download");

  return [{ data: [] }, false];
}
