import React, { useState } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

//SimpleBar
import SimpleBar from "simplebar-react";

import { useSelector, useDispatch } from "react-redux";
import {
  instantPurchase,
  removeCartData,
} from "../../store/reportSearch/action";

import EmptyCartImage from "../../assets/images/empty-cart.png";
import { useOrganization } from "../../context/OrganizationContext";

function CartItem({ state, jurisdiction, cartItems, totalPrice, count }) {
  const dispatch = useDispatch();
  function onRemove() {
    cartItems.forEach((cartItem) => {
      dispatch(removeCartData(cartItem));
    });
  }
  return (
    <div className="d-block dropdown-item text-wrap px-3 py-2">
      <div className="d-flex align-items-center">
        <div
          style={{
            fontWeight: "bold",
            display: "grid",
            placeItems: "center",
            fontSize: "1.1rem",
            color: "grey",
          }}
          className="me-3 rounded-circle avatar-sm p-2 bg-light"
        >
          {state}
        </div>
        <div className="flex-1">
          <h6 className="mt-0 mb-1 fs-14">
            <Link to="#" className="text-reset">
              {jurisdiction}
            </Link>
          </h6>
          <p className="mb-0 fs-12 text-muted">
            Quantity: <span>{count}</span>
          </p>
        </div>
        <div className="px-2">
          <h5 className="m-0 fw-normal">${totalPrice}</h5>
        </div>
        <div className="ps-2">
          <button
            type="button"
            onClick={onRemove}
            className="btn btn-icon btn-sm btn-ghost-secondary"
          >
            <i className="ri-close-fill fs-16"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

const MyCartDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { purchaseItems, userCreditBalance, getPurchaseHistory } =
    useOrganization();
  const [isCartDropdown, setIsCartDropdown] = useState(false);

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);

  const { cartItems } = useSelector((state) => {
    return {
      cartItems: state.ReportSearch.cartItems,
    };
  });

  const toggleCartDropdown = () => {
    setIsCartDropdown(!isCartDropdown);
  };

  async function instantBuy() {
    const totalPrice = cartItems.reduce((acc, curr) => acc + curr.Price, 0);

    if (totalPrice > userCreditBalance) {
      setSuccessMsg(
        "Insufficient Credit Balance. Please add more credit to your account",
      );
      toggleSuccessModal();
      return;
    }

    dispatch(instantPurchase(cartItems));
    cartItems.forEach((cartItem) => dispatch(removeCartData(cartItem)));
    history.push("/downloaded-reports");
    toggleCartDropdown();

    const [ok, reason] = await purchaseItems(cartItems);

    if (ok) {
      getPurchaseHistory();
      return;
    }

    if (reason === "Insufficient balance") {
      setSuccessMsg(
        "Insufficient Credit Balance. Please add more credit to your account",
      );
      toggleSuccessModal();
    }
  }

  const cartItemsDisplay = cartItems.reduce((acc, curr) => {
    if (!acc[curr.jurisdiction]) {
      acc[curr.jurisdiction] = {
        jurisdiction: curr.jurisdiction,
        count: 0,
        price: 0,
        cartItems: [],
        state: curr.state,
      };
    }
    acc[curr.jurisdiction].count += 1;
    acc[curr.jurisdiction].price += curr.Price;
    acc[curr.jurisdiction].cartItems.push(curr);
    return acc;
  }, {});

  return (
    <>
      <Dropdown
        isOpen={isCartDropdown}
        toggle={toggleCartDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-cart-alt fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-info">
            {cartItems.length}
            <span className="visually-hidden">unread messages</span>
          </span>
        </DropdownToggle>

        {cartItems.length ? (
          <DropdownMenu
            className="dropdown-menu-xl dropdown-menu-end p-0"
            aria-labelledby="page-header-cart-dropdown"
          >
            <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold"> My Cart</h6>
                </Col>
                <div className="col-auto">
                  <span className="badge badge-soft-warning fs-13">
                    {" "}
                    {cartItems.length} items
                  </span>
                </div>
              </Row>
            </div>
            <SimpleBar style={{ maxHeight: "300px" }}>
              <div className="p-2">
                {Object.entries(cartItemsDisplay).map(
                  ([jurisdiction, item], index) => {
                    return (
                      <CartItem
                        key={index}
                        cartItems={item.cartItems}
                        state={item.state}
                        jurisdiction={jurisdiction}
                        totalPrice={item.price}
                        count={item.count}
                      />
                    );
                  },
                )}
              </div>
            </SimpleBar>
            <div className="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border d-grid">
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5 className="m-0 text-muted">Total:</h5>
                <div className="px-2">
                  <h5 className="m-0">
                    $
                    {cartItems
                      .map(({ Price }) => Price)
                      .reduce((acc, curr) => acc + curr, 0)}
                  </h5>
                </div>
              </div>

              <Link
                to="#"
                onClick={instantBuy}
                className="btn btn-danger text-center"
              >
                Instant Purchase
              </Link>
            </div>
          </DropdownMenu>
        ) : (
          <DropdownMenu
            className="dropdown-menu-xl dropdown-menu-end p-0"
            aria-labelledby="page-header-cart-dropdown"
          >
            <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold"> My Cart</h6>
                </Col>
                <div className="col-auto">
                  <span className="badge badge-soft-warning fs-13">
                    {" "}
                    {cartItems.length} items
                  </span>
                </div>
              </Row>
            </div>
            <div
              style={{
                backgroundImage: `url('${EmptyCartImage}')`,
                height: "365px",
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundPositionY: "-45px",
              }}
            ></div>
          </DropdownMenu>
        )}
      </Dropdown>

      <Modal isOpen={successModalOpen} toggle={toggleSuccessModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0">
            <div
              className="alert alert-danger alert-dismissible fade show m-0"
              role="alert"
            >
              <div dangerouslySetInnerHTML={{ __html: successMsg }} />
              <button
                type="button"
                className="btn-close"
                onClick={toggleSuccessModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default MyCartDropdown;
