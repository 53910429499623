import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useOrganization } from "../../context/OrganizationContext";

const data = {
  "Available Credits": {
    id: 1,
    label: "Available Credits",
    badge: "ri-arrow-up-circle-line text-success",
    icon: "ri-exchange-dollar-line",
    counter: "-",
    decimals: 2,
    suffix: "",
    prefix: "$",
  },
  "Average Credits Spent per Day": {
    id: 2,
    label: "Average Credits Spent per Day",
    badge: "ri-arrow-up-circle-line text-success",
    icon: "ri-pulse-line",
    counter: "-",
    decimals: 2,
    suffix: "",
    prefix: "$",
  },
  "Most purchased jurisdiction": {
    id: 3,
    label: "Most purchased jurisdiction",
    icon: "ri-trophy-line",
    type: "text",
    text: "-",
  },
};

const TopWidget = () => {
  const { userOrganizationData, userCreditsLogData, userOcrLogData } = useOrganization();

  const [widgetData, setWidgetData] = useState(data);

  useEffect(() => {
    if (userOrganizationData && userCreditsLogData && userOcrLogData) {
      // available credits
      const { credit_balance } = userOrganizationData;

      data["Available Credits"] = {
        ...data["Available Credits"],
        counter: credit_balance > 999 ? `${(credit_balance / 1000).toFixed(2)}` : credit_balance,
        suffix: credit_balance > 999 ? "k" : "",
      };

      // average credits spent per day

      const total_amount_spent = [...userCreditsLogData, ...userOcrLogData].reduce((acc, curr) => acc + curr.amount, 0);
      const total_days = Array.from(new Set([...userCreditsLogData, ...userOcrLogData].map((e) => e.date_of_purchase))).length;

      const average_spent = total_amount_spent / total_days;

      data["Average Credits Spent per Day"] = {
        ...data["Average Credits Spent per Day"],
        counter: average_spent > 999 ? `${(average_spent / 1000).toFixed(2)}` : average_spent,
        suffix: average_spent > 999 ? "k" : "",
      };

      // Most purchased jurisdiction
      const juryCounts = userCreditsLogData.reduce((acc, curr) => {
        const reports = JSON.parse(curr.reports_json);
        reports.forEach((report) => {
          if (!acc[report.jurisdiction]) acc[report.jurisdiction] = 0;
          acc[report.jurisdiction] += 1;
        });
        return acc;
      }, {});

      const sortedJuries = Object.entries(juryCounts).sort((a, b) => b[1] - a[1]);

      if (sortedJuries.length) {
        const [mostPurchasedJury, purchasedTime] = sortedJuries[0];

        data["Most purchased jurisdiction"] = {
          ...data["Most purchased jurisdiction"],
          text: mostPurchasedJury,
        };
      }

      setWidgetData(data);
    }
  }, [userOrganizationData, userCreditsLogData, userOcrLogData]);

  return (
    <React.Fragment>
      <div className="col-xl-12">
        <div className="card crm-widget">
          <div className="card-body p-0">
            <div className="row row-cols-xxl-3 row-cols-md-2 row-cols-1 g-0">
              {Object.entries(widgetData).map(([key, widget], index) => (
                <div className="col" key={index}>
                  <div className="py-4 px-3">
                    <h5 className="text-muted text-uppercase fs-13">
                      {widget.label}
                      {widget.badge && <i className={widget.badge + " fs-18 float-end align-middle"}></i>}
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className={widget.icon + " display-6 text-muted"}></i>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h2 className="mb-0">
                          <span className="counter-value" data-target="197">
                            {widget.type === "text" ? (
                              <span>{widget.text}</span>
                            ) : (
                              <CountUp start={0} prefix={widget.prefix} suffix={widget.suffix} separator={widget.separator} end={widget.counter} decimals={widget.decimals} duration={0.7} />
                            )}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopWidget;
