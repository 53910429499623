import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getReportData, addCartData, removeCartData } from "../../store/reportSearch/action";
import { ColumnFilter } from "../../common";
import { Progress } from "reactstrap";
import { objectArrayDateSorterAsc, randomInteger } from "../../util";
import "./ReportTable.css";
import { useAuth } from "../../context/AuthContext";
import { useOrganization } from "../../context/OrganizationContext";

function AddRemoveCartBtn({ inCart, reportItem, previouslyDownloaded }) {
  const dispatch = useDispatch();

  function onClick() {
    if (inCart) {
      dispatch(removeCartData(reportItem));
    } else {
      dispatch(addCartData(reportItem));
    }
  }
  const btnClass = inCart ? "btn-danger" : "btn-success";
  const iconClass = inCart ? "bxs-shopping-bag" : "bx-shopping-bag";
  const btnText = inCart ? "Remove" : "Add";

  const style =
    previouslyDownloaded === "Yes"
      ? {
          backgroundColor: "rgba(128, 128, 128, 0.5)",
          borderColor: "rgba(128, 128, 128, 0.2)",
        }
      : {};

  return (
    <button onClick={onClick} disabled={previouslyDownloaded === "Yes"} className={`cart-btn btn btn-sm ${btnClass} btn-label`} style={style}>
      <i className={`bx ${iconClass} label-icon align-middle`}></i>
      <div className="cart-btn-txt">{btnText}</div>
    </button>
  );
}

function chooseOneBadge(bought) {
  const type = bought === "Yes" ? "success" : "primary";
  return <span className={`badge badge-soft-${type}`}>{bought}</span>;
}

export default function ReportTable({ selectedDate, selectedState, selectedJurisdiction }) {
  // const { getReports } = useOrganization()
  const { userIsAdmin, userOrg, getUserToken } = useAuth();
  const dispatch = useDispatch();

  const { cartItemsIDs, reportItems, purchasedItems } = useSelector((state) => {
    return {
      cartItemsIDs: state.ReportSearch.cartItems.map((item) => item.id),
      reportItems: state.ReportSearch.reportItems || [],
      purchasedItems: state.ReportSearch.purchasedItems || [],
    };
  });

  const initialFilterAt = {
    "Report Type": [],
    "Agency Name": [],
    "Crash Date": [],
    "Number of Cars Involved": [],
    "Car Year": [],
    "Car Make": [],
    "Car Model": [],
    "Car Plate": [],
    Price: [],
    "Previously Downloaded": [],
    "Add to Cart": [],
  };

  const initialSortAt = {
    "Report Type": 2,
    "Agency Name": 2,
    "Crash Date": 2,
    "Number of Cars Involved": 2,
    "Car Year": 2,
    "Car Make": 2,
    "Car Model": 2,
    "Car Plate": 2,
    Price: 2,
    "Previously Downloaded": 2,
    "Add to Cart": 2,
  };

  const [allTableData, setAllTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filterAt, setFilterAt] = useState(initialFilterAt);
  const [sortAt, setSortAt] = useState(initialSortAt);
  const [filterOrderKey, setFilterOrderKey] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (reportItems.length && typeof reportItems[0] === "object") {
      const data = reportItems.map((item) => {
        for (let pitems of purchasedItems) {
          if (pitems.id === item.id) {
            item["Previously Downloaded"] = "Yes";
            break;
          }
        }

        return item;
      });
      setAllTableData(data);
      setFilteredTableData(data);
    }
  }, [reportItems, purchasedItems]);

  function resetTable() {
    setAllTableData([]);
    setFilteredTableData([]);
    setFilterAt(initialFilterAt);
    setSortAt(initialSortAt);
    setFilterOrderKey([]);
    setShow(false);
    setLoading(false);
  }

  useEffect(() => {
    resetTable();
  }, [selectedDate, selectedState, selectedJurisdiction]);

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setProgressValue(0);
    dispatch(
      getReportData({
        token: await getUserToken(),
        organization_id: userOrg,
        state: selectedState,
        jurisdictions: selectedJurisdiction,
        start_date: selectedDate[0],
        end_date: selectedDate[1],
        setProgress: (progress) => {
          setProgressValue(progress >= 100 ? 100 : progress);
          if (progress >= 100) {
            setTimeout(() => {
              setLoading(false);
              setShow(true);
            }, 580);
          }
        },
      })
    );
  }

  function updateSortAt(colKey) {
    setSortAt({ ...initialSortAt, [colKey]: (sortAt[colKey] + 1) % 3 });
  }

  function getSortingKey() {
    for (let [key, value] of Object.entries(sortAt)) {
      if (value === 0) {
        return [key, "asc"];
      } else if (value === 1) {
        return [key, "desc"];
      }
    }

    return ["", ""];
  }

  function sortData(data) {
    const [sortKey, order] = getSortingKey();

    if (order === "asc") {
      if (sortKey === "Crash Date") {
        data.sort((a, b) => new Date(a[sortKey]) - new Date(b[sortKey]));
      } else if (sortKey === "Add to Cart") {
        if (userIsAdmin) {
          data.sort((a, b) => {
            const aIndex = a.downloaded ? 1 : 0;
            const bIndex = b.downloaded ? 1 : 0;
            return aIndex - bIndex;
          });
        } else {
          data.sort((a, b) => {
            const aIndex = cartItemsIDs.includes(a.id) ? 0 : a["Previously Downloaded"] === "Yes" ? 2 : 1;
            const bIndex = cartItemsIDs.includes(b.id) ? 0 : b["Previously Downloaded"] === "Yes" ? 2 : 1;
            return aIndex - bIndex;
          });
        }
      } else {
        data.sort((a, b) => a[sortKey]?.toString()?.localeCompare(b[sortKey]?.toString()));
      }
    } else if (order === "desc") {
      if (sortKey === "Crash Date") {
        data.sort((b, a) => new Date(a[sortKey]) - new Date(b[sortKey]));
      } else if (sortKey === "Add to Cart") {
        if (userIsAdmin) {
          data.sort((b, a) => {
            const aIndex = a.downloaded ? 1 : 0;
            const bIndex = b.downloaded ? 1 : 0;
            return aIndex - bIndex;
          });
        } else {
          data.sort((b, a) => {
            const aIndex = cartItemsIDs.includes(a.id) ? 0 : a["Previously Downloaded"] === "Yes" ? 2 : 1;
            const bIndex = cartItemsIDs.includes(b.id) ? 0 : b["Previously Downloaded"] === "Yes" ? 2 : 1;
            return aIndex - bIndex;
          });
        }
      } else {
        data.sort((b, a) => a[sortKey]?.toString()?.localeCompare(b[sortKey]?.toString()));
      }
    }
    return data;
  }

  function filterData(data) {
    for (let [key, value] of Object.entries(filterAt)) {
      const values = value.map((e) => e.value);
      if (values.length === 0) {
        if (filterOrderKey.includes(key)) setFilterOrderKey(filterOrderKey.filter((e) => e !== key));
        continue;
      }

      if (!filterOrderKey.includes(key)) setFilterOrderKey([...filterOrderKey, key]);

      if (key === "Number of Cars Involved") {
        data = data.filter((e) => {
          if (values.includes("Multiple Cars") && e[key] > 1) return true;
          if (values.includes("Single Car") && e[key] === 1) return true;
        });
      } else if (key === "Car Plate") {
        data = data
          .map((e) => {
            for (let v of values) {
              if (e[key].includes(v)) return e;
            }
          })
          .filter((e) => e);
      } else {
        data = data.filter((e) => values.includes(e[key]));
      }
    }

    return data;
  }

  useEffect(() => {
    setFilteredTableData(sortData(filterData([...allTableData])));
  }, [sortAt, filterAt, allTableData]);

  function getSortClass(colKey) {
    return ["bx-sort-a-z", "bx-sort-z-a", "bx-sort-z-a"][sortAt[colKey]];
  }

  function getFilterOptions(colKey) {
    if (colKey === "Number of Cars Involved") {
      return [
        { value: "Multiple Cars", label: "Multiple Cars" },
        { value: "Single Car", label: "Single Car" },
      ];
    }

    let options = [...allTableData];

    const fIndex = filterOrderKey.findIndex((e) => e === colKey);
    if (fIndex !== -1) {
      for (let i = 1; i <= fIndex; i++) {
        const preKey = filterOrderKey[i - 1];
        const preOptions = filterAt[preKey].map((e) => e.value);
        if (preKey === "Number of Cars Involved") {
          options = options.filter((e) => {
            if (preOptions.includes("Multiple Cars") && e[preKey] > 1) return true;
            if (preOptions.includes("Single Car") && e[preKey] === 1) return true;
          });
        } else if (preKey === "Car Plate") {
          options = options
            .map((e) => {
              for (let v of preOptions) {
                if (e[preKey].includes(v)) return e;
              }
            })
            .filter((e) => e);
        } else {
          options = options.filter((e) => preOptions.includes(e[preKey]));
        }
      }
    } else {
      options = [...filteredTableData];
    }

    let toptions = null;

    if (colKey === "Car Plate") {
      toptions = options.reduce((acc, curr) => {
        curr[colKey].forEach((item) => acc.add(item));
        return acc;
      }, new Set());
    } else {
      toptions = options.reduce((acc, curr) => {
        acc.add(curr[colKey]);
        return acc;
      }, new Set());
    }

    const result = [...toptions.values()].map((e) => ({ value: e, label: e }));

    return result;
  }

  function addAllReports() {
    if (filteredTableData.length) {
      filteredTableData.forEach((report) => {
        if (cartItemsIDs.includes(report.id) || report["Previously Downloaded"] === "Yes") return;
        dispatch(addCartData(report));
      });
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pt-4 pb-2 gap-4" style={{ visibility: show ? "visible" : "hidden" }}>
        <div className="fs-14" style={{ fontWeight: "500" }}>
          Total Reports: {filteredTableData.length}
        </div>
        {!userIsAdmin && (
          <button className="cart-btn btn btn-sm btn-primary btn-label" onClick={addAllReports}>
            <i className={`bx bxs-shopping-bags label-icon align-middle`}></i>
            <div className="cart-btn-txt">Add All Reports to Cart</div>
          </button>
        )}
      </div>
      <div id="report-table-container" data-show={show}>
        {!loading && (
          <button onClick={onSubmit} id="show-table-btn" className="btn btn-primary btn-label" disabled={selectedDate.length < 2 || selectedState === "" || selectedJurisdiction.length === 0}>
            <i className="bx bx-search fs-19 label-icon align-middle"></i>
            <span>Search</span>
          </button>
        )}
        {loading && (
          <div id="reports-table-progress">
            <div className="text-center">{progressValue}%</div>
            <Progress value={progressValue} />
          </div>
        )}
        <div className="table-responsive">
          <table id="report-table" className="table table-bordered table-nowrap mb-0">
            <thead>
              <tr>
                <th scope="col" id="report-type-header">
                  <div>
                    <span>Report Type</span>
                    <span className="filters-and-sort-icons">
                      <i onClick={() => updateSortAt("Report Type")} className={`bx ${getSortClass("Report Type")}`}></i>
                      <ColumnFilter total={11} index={0} colKey="Report Type" options={getFilterOptions("Report Type")} setFilterAt={setFilterAt} filterAt={filterAt} />
                    </span>
                  </div>
                </th>
                <th scope="col" id="agency-name-header">
                  <div>
                    <span>Agency Name</span>
                    <span className="filters-and-sort-icons">
                      <i onClick={() => updateSortAt("Agency Name")} className={`bx ${getSortClass("Agency Name")}`}></i>
                      <ColumnFilter total={11} index={1} colKey="Agency Name" options={getFilterOptions("Agency Name")} setFilterAt={setFilterAt} filterAt={filterAt} />
                    </span>
                  </div>
                </th>
                <th scope="col" id="crash-date-header">
                  <div>
                    <span>Crash Date</span>
                    <span className="filters-and-sort-icons">
                      <i onClick={() => updateSortAt("Crash Date")} className={`bx ${getSortClass("Crash Date")}`}></i>
                      <ColumnFilter
                        total={11}
                        index={2}
                        colKey="Crash Date"
                        optionsSorter={objectArrayDateSorterAsc("Crash Date")}
                        options={getFilterOptions("Crash Date")}
                        setFilterAt={setFilterAt}
                        filterAt={filterAt}
                      />
                    </span>
                  </div>
                </th>
                <th scope="col" id="no-of-cars-header">
                  <div>
                    <span>
                      Number of
                      <br />
                      Cars Involved
                    </span>
                    <span className="filters-and-sort-icons">
                      <i onClick={() => updateSortAt("Number of Cars Involved")} className={`bx ${getSortClass("Number of Cars Involved")}`}></i>
                      <ColumnFilter total={11} index={3} colKey="Number of Cars Involved" options={getFilterOptions("Number of Cars Involved")} setFilterAt={setFilterAt} filterAt={filterAt} />
                    </span>
                  </div>
                </th>
                <th scope="col" className="cars-info">
                  Car Year
                </th>
                <th scope="col" className="cars-info">
                  Car Make
                </th>
                <th scope="col" className="cars-info">
                  Car Model
                </th>
                <th scope="col" className="cars-info" id="car-plate-header">
                  <div>
                    <span>Car Plate</span>
                    <span className="filters-and-sort-icons">
                      <ColumnFilter total={11} index={7} colKey="Car Plate" options={getFilterOptions("Car Plate")} setFilterAt={setFilterAt} filterAt={filterAt} />
                    </span>
                  </div>
                </th>
                <th scope="col" id="price-header">
                  <div>
                    <span>Price</span>
                    <span className="filters-and-sort-icons">
                      <i onClick={() => updateSortAt("Price")} className={`bx ${getSortClass("Price")}`}></i>
                      <ColumnFilter total={11} index={8} colKey="Price" options={getFilterOptions("Price")} setFilterAt={setFilterAt} filterAt={filterAt} />
                    </span>
                  </div>
                </th>
                <th scope="col" id="previously-downloaded-header">
                  <div>
                    <span>
                      Previously
                      <br />
                      Downloaded?
                    </span>
                    <span className="filters-and-sort-icons">
                      <i onClick={() => updateSortAt("Previously Downloaded")} className={`bx ${getSortClass("Previously Downloaded")}`}></i>
                      <ColumnFilter total={11} index={9} colKey="Previously Downloaded" options={getFilterOptions("Previously Downloaded")} setFilterAt={setFilterAt} filterAt={filterAt} />
                    </span>
                  </div>
                </th>
                <th scope="col" id="add-to-cart-header">
                  <div>
                    <span>{userIsAdmin ? "Download" : "Add to Cart"}</span>
                    <span className="filters-and-sort-icons">
                      <i onClick={() => updateSortAt("Add to Cart")} className={`bx ${getSortClass("Add to Cart")}`}></i>
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {!show
                ? [...Array(20).keys()].map((key) => (
                    <tr key={key}>
                      <td>Lorem ipsum</td>
                      <td>Lorem ipsum</td>
                      <td>Lorem ipsum</td>
                      <td>Lorem ipsum</td>
                      <td>Lorem ipsum</td>
                      <td>Lorem ipsum</td>
                      <td>Lorem ipsum</td>
                      <td>Lorem ipsum</td>
                      <td>{chooseOneBadge(Math.random() > 0.5)}</td>
                      <td>
                        <AddRemoveCartBtn inCart={false} reportItem={{}} />
                      </td>
                    </tr>
                  ))
                : filteredTableData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{data["Report Type"]}</td>
                        <td>{data["Agency Name"]}</td>
                        <td>{data["Crash Date"]}</td>
                        <td>{data["Number of Cars Involved"]}</td>
                        <td>
                          <ul>
                            {data["Car Year"].map((year, index) => {
                              return <li key={index}>{year}</li>;
                            })}
                          </ul>
                        </td>
                        <td>
                          <ul>
                            {data["Car Make"].map((make, index) => {
                              return <li key={index}>{make}</li>;
                            })}
                          </ul>
                        </td>
                        <td>
                          <ul>
                            {data["Car Model"].map((modal, index) => {
                              return <li key={index}>{modal}</li>;
                            })}
                          </ul>
                        </td>
                        <td>
                          <ul>
                            {data["Car Plate"].map((plate, index) => {
                              return <li key={index}>{plate}</li>;
                            })}
                          </ul>
                        </td>

                        <td>${data["Price"]?.toFixed(2)}</td>
                        <td>{chooseOneBadge(data["Previously Downloaded"])}</td>
                        <td>
                          {userIsAdmin ? (
                            <DirectDownloadBtn reportItem={data} />
                          ) : (
                            <AddRemoveCartBtn inCart={cartItemsIDs.includes(data.id)} reportItem={data} previouslyDownloaded={data["Previously Downloaded"]} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

function DirectDownloadBtn({ reportItem }) {
  const [loading, setLoading] = useState(false);
  const { downloadReport } = useOrganization();

  const icon = loading ? <i className="mdi mdi-loading mdi-spin label-icon align-middle"></i> : <i className="mdi mdi-download-box-outline label-icon align-middle"></i>;

  async function handleClick() {
    if (loading) return;
    setLoading(true);
    let { report_number, pdf_data } = await downloadReport(reportItem.id);
    pdf_data = window.atob(pdf_data);
    let buffer = new ArrayBuffer(pdf_data.length),
      view = new Uint8Array(buffer);
    for (let i = 0; i < pdf_data.length; i++) {
      view[i] = pdf_data.charCodeAt(i);
    }
    const downloadFileName = `${reportItem["state"]}_${report_number}_${reportItem["Crash Date"]}.pdf`;
    window.saveAs(new Blob([view], { type: "application/pdf" }), downloadFileName);
    setLoading(false);
  }

  const style = reportItem.downloaded
    ? {}
    : {
        backgroundColor: "rgba(128, 128, 128, 0.5)",
        borderColor: "rgba(128, 128, 128, 0.2)",
      };

  return (
    <button onClick={handleClick} className={`cart-btn btn btn-sm btn-success btn-label`} disabled={!reportItem.downloaded} style={style}>
      {icon}
      <div className="cart-btn-txt">Download</div>
    </button>
  );
}
