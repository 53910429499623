import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  UncontrolledDropdown,
  UncontrolledCollapse,
  Table,
} from "reactstrap";
import OrganizationUsersTable from "./OrganizationUsersTable";
import { getInitials } from "../../util";
import { useOrganization } from "../../context/OrganizationContext";

function EnableDisableOrganization({
  id,
  name,
  disabled,
  ohio_crash_app_user,
  metropolitan_nashville_mail_user,
  setSuccessMsg,
  toggleSuccessModal,
}) {
  const {
    disabledOrEnableOrganization,
    toggleOhioCrashApp,
    toggleNashvilleMailUser,
  } = useOrganization();

  async function onOrgEnableDisableChange(e) {
    const disable = !e.target.checked;
    const status = await disabledOrEnableOrganization(id);
    if (status) {
      setSuccessMsg(
        `<strong> ${name} </strong> is ${disable ? "disabled" : "enabled"} now.`,
      );
    } else {
      setSuccessMsg(`Failed to update organization`);
    }
    toggleSuccessModal();
  }

  async function onOrgOhioAppEnableDisableChange(e) {
    const disable = !e.target.checked;
    const status = await toggleOhioCrashApp(id);
    if (status) {
      setSuccessMsg(
        `<strong> ${name} </strong> is ${disable ? "not a Ohio Crash App User" : "a Ohio Crash App User"} now.`,
      );
    } else {
      setSuccessMsg(`Failed to update organization`);
    }
    toggleSuccessModal();
  }

  async function onNashvilleMailUserChange(e) {
    const disable = !e.target.checked;
    const status = await toggleNashvilleMailUser(id);
    if (status) {
      setSuccessMsg(
        `<strong> ${name} </strong> is ${disable ? "not a Nashville Mail User" : "a Nashville Mail User"} now.`,
      );
    } else {
      setSuccessMsg(`Failed to update organization`);
    }
    toggleSuccessModal();
  }

  return (
    <tr>
      <td>{name}</td>
      <td>
        <div className="form-check form-switch">
          <input
            onChange={onOrgEnableDisableChange}
            type="checkbox"
            className="form-check-input"
            checked={!disabled}
            role="switch"
          />
        </div>
      </td>
      <td>
        <div className="form-check form-switch">
          <input
            onChange={onOrgOhioAppEnableDisableChange}
            type="checkbox"
            className="form-check-input"
            checked={ohio_crash_app_user}
            role="switch"
          />
        </div>
      </td>
      <td>
        <div className="form-check form-switch">
          <input
            onChange={onNashvilleMailUserChange}
            type="checkbox"
            className="form-check-input"
            checked={metropolitan_nashville_mail_user}
            role="switch"
          />
        </div>
      </td>
    </tr>
  );
}

function OrganizationComp({ org, setSelectedOrg, toggleMemberModal }) {
  return (
    <div>
      <Col>
        <Card className="team-box">
          <div className="team-cover">
            <img src={org.backgroundImg} alt="" className="img-fluid" />
          </div>
          <CardBody className="p-4">
            <Row className="align-items-center team-row">
              <Col sm={6}>
                <Row>
                  <Col sm={6}>
                    <div className="team-profile-img">
                      <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                        <div
                          className={
                            "avatar-title rounded-circle bg-soft-success" +
                            " text-success"
                          }
                        >
                          {getInitials(org.name)}
                        </div>
                      </div>
                      <div
                        className="team-content"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Link to="#">
                          <h5 className="fs-17 mb-0">{org.name}</h5>
                        </Link>
                        {org.disabled && (
                          <div
                            className="text-danger fs-14"
                            style={{ fontStyle: "italic" }}
                          >
                            Disabled
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col
                    sm={6}
                    className="border-end border-end-dashed"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Row className="text-muted text-center">
                      <Col>
                        <h5 className="mb-1">{org.members.length}</h5>
                        <p className="text-muted mb-0">Members</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <Row>
                  <Col sm={10}>
                    <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
                      <Button id={"organization-row-" + org.id} color="primary">
                        View Organization
                      </Button>
                    </div>
                  </Col>
                  <Col sm={2} style={{ display: "flex", alignItems: "center" }}>
                    <UncontrolledDropdown
                      direction="start"
                      className="col text-end"
                    >
                      <DropdownToggle
                        tag="a"
                        id="dropdownMenuLink2"
                        role="button"
                      >
                        <i className="ri-more-fill fs-17"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setSelectedOrg({ ...org });
                            toggleMemberModal();
                          }}
                        >
                          <i className="ri-add-fill me-1 align-bottom"></i>
                          Add Member
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <UncontrolledCollapse
        id={"organization-drop-" + org.id}
        toggler={"#organization-row-" + org.id}
      >
        <OrganizationUsersTable members={org.members} />
      </UncontrolledCollapse>
    </div>
  );
}

export default function ManageOrganizations() {
  const { addMember, addOrganization, organizationsData } = useOrganization();
  const [organizationModalOpen, setOrganizationModalOpen] = useState(false);
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [disableEnableOrgModal, setDisableEnableOrgModal] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState();
  const [successMsg, setSuccessMsg] = useState("");

  const toggleOrganizationModal = () =>
    setOrganizationModalOpen(!organizationModalOpen);
  const toggleMemberModal = () => setMemberModalOpen(!memberModalOpen);
  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
  const toggleDisableEnableOrgModal = () =>
    setDisableEnableOrgModal(!disableEnableOrgModal);

  async function onOrganizationFormSubmit(e) {
    e.preventDefault();
    let { organizationName } = e.target.elements;
    organizationName = organizationName.value.trim();
    const successful = await addOrganization(organizationName);
    if (successful) {
      setSuccessMsg(
        `<strong> ${organizationName} </strong> is added successfully`,
      );
      toggleOrganizationModal();
      toggleSuccessModal();
    }
  }

  async function onMemberFormSubmit(e) {
    e.preventDefault();
    const { memberName, memberEmail, memberMobile } = e.target.elements;

    const successful = await addMember({
      memberOrganizationId: selectedOrg.id,
      memberOrganizationName: selectedOrg.name,
      memberName: memberName.value.trim(),
      memberEmail: memberEmail?.value?.trim() || "",
      memberMobile: memberMobile?.value?.trim() || "",
    });
    if (successful) {
      setSuccessMsg(
        `<strong> ${memberName.value.trim()} </strong> is added to ${selectedOrg.name}`,
      );
      toggleMemberModal();
      toggleSuccessModal();
    }
  }

  const orgEnableDisableItems = Object.entries(organizationsData || []).map(
    ([id, org]) => {
      const {
        name,
        disabled,
        ohio_crash_app_user,
        metropolitan_nashville_mail_user,
      } = org;
      return {
        id,
        name,
        disabled,
        ohio_crash_app_user,
        metropolitan_nashville_mail_user,
      };
    },
  );

  return (
    <>
      <Col>
        <Card>
          <CardBody>
            <Row className="g-2">
              <Col sm={4}>
                <div className="search-box">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search for organization, name..."
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
              </Col>
              <Col
                className="col-sm-auto d-flex ms-auto"
                style={{ gap: "1rem" }}
              >
                <div className="list-grid-nav hstack gap-1">
                  <Button color="info" onClick={toggleDisableEnableOrgModal}>
                    Organization Level Settings
                  </Button>
                </div>
                <div className="list-grid-nav hstack gap-1">
                  <Button color="success" onClick={toggleOrganizationModal}>
                    <i className="ri-add-fill me-1 align-bottom"></i> Add
                    Organization
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row>
          <Col>
            <div>
              <Row className="team-list list-view-filter">
                {Object.entries(organizationsData || {}).map(([key, org]) => (
                  <OrganizationComp
                    key={key}
                    org={org}
                    setSelectedOrg={setSelectedOrg}
                    toggleMemberModal={toggleMemberModal}
                  />
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </Col>

      <Modal isOpen={successModalOpen} toggle={toggleSuccessModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0">
            <div
              className="alert alert-success alert-dismissible fade show m-0"
              role="alert"
            >
              <div dangerouslySetInnerHTML={{ __html: successMsg }} />
              <button
                type="button"
                className="btn-close"
                onClick={toggleSuccessModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={organizationModalOpen}
        toggle={toggleOrganizationModal}
        centered
      >
        <div className="modal-content border-0">
          <div className="modal-header p-3 bg-soft-info">
            <h5 className="modal-title" id="myModalLabel">
              Add New Organization
            </h5>
            <button
              onClick={toggleOrganizationModal}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <ModalBody>
            <Form onSubmit={onOrganizationFormSubmit}>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <Label htmlFor="organizationName" className="form-label">
                      Organization Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="organizationName"
                      name="organizationName"
                      placeholder="Enter organization name"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      onClick={toggleOrganizationModal}
                      type="button"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-success">
                      Add Organization
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </div>
      </Modal>

      <Modal isOpen={memberModalOpen} toggle={toggleMemberModal} centered>
        <div className="modal-content border-0">
          <div className="modal-header p-3 bg-soft-info">
            <h5 className="modal-title" id="myModalLabel">
              Add New User
            </h5>
            <button
              onClick={toggleMemberModal}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <ModalBody>
            <Form onSubmit={onMemberFormSubmit}>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <Label htmlFor="memberName" className="form-label">
                      User Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="memberName"
                      name="memberName"
                      placeholder="Enter user name"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-3">
                    <Label htmlFor="memberEmail" className="form-label">
                      User Email
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="memberEmail"
                      name="memberEmail"
                      placeholder="Enter user email"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-3">
                    <Label htmlFor="memberMobile" className="form-label">
                      User Mobile
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="memberMobile"
                      name="memberMobile"
                      placeholder="Enter user mobile"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      onClick={toggleMemberModal}
                      type="button"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-success">
                      Add User
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={disableEnableOrgModal}
        size="lg"
        toggle={toggleDisableEnableOrgModal}
        centered
      >
        <div className="modal-content border-0">
          <div className="modal-header p-3 bg-soft-info">
            <h5 className="modal-title" id="myModalLabel">
              Organization Level Settings
            </h5>
            <button
              onClick={toggleDisableEnableOrgModal}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <ModalBody>
            <Table striped>
              <thead>
                <tr>
                  <th>Organization Name</th>
                  <th>Enable/ Disable</th>
                  <th>Is Ohio Crash App User?</th>
                  <th>Is Metropolitan Nashville Mail User?</th>
                </tr>
              </thead>
              <tbody>
                {orgEnableDisableItems.map((orgData, key) => (
                  <EnableDisableOrganization
                    key={key}
                    {...orgData}
                    setSuccessMsg={setSuccessMsg}
                    toggleSuccessModal={toggleSuccessModal}
                  />
                ))}
              </tbody>
            </Table>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}
