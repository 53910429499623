import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const AuthProtected = (props) => {
  const { currentUser } = useAuth();

  if (!currentUser) return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;

  return props.children;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  const { userIsAdmin } = useAuth();
  if (rest.admin && rest.member) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  if (userIsAdmin && rest.admin) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  if (!userIsAdmin && rest.member) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  return <Redirect to={{ pathname: "/search" }} />;
};

export { AuthProtected, AccessRoute };
