import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Modal, ModalBody } from "reactstrap";
import {
  objectArrayStringSorterAsc,
  objectArrayStringSorterDesc,
  objectArrayDateSorterAsc,
  objectArrayDateSorterDesc,
  objectArrayNumberSorterAsc,
  objectArrayNumberSorterDesc,
  stringOptionsSorter,
  dateStampToString,
  floatToCurrenctyTxt,
} from "../../util";
import { FilterAndSortHeaders, createHeaderData } from "../../common";
import { useOrganization } from "../../context/OrganizationContext";

const { headerData, headerDataObject, initialSortAt, initialFilterAt } =
  createHeaderData([
    {
      name: "Organizations",
      colKey: "organization",
      ascSorter: objectArrayStringSorterAsc,
      descSorter: objectArrayStringSorterDesc,
      optionsSorter: stringOptionsSorter,
    },
    {
      name: "Start Date",
      colKey: "startDate",
      ascSorter: objectArrayDateSorterAsc,
      descSorter: objectArrayDateSorterDesc,
    },
    {
      name: "Last Credit Topup Date",
      colKey: "lastCreditTopupDate",
      ascSorter: objectArrayDateSorterAsc,
      descSorter: objectArrayDateSorterDesc,
    },
    {
      name: "Total Credits Purchased",
      colKey: "totalCreditsPurchased",
      ascSorter: objectArrayNumberSorterAsc,
      descSorter: objectArrayNumberSorterDesc,
    },
    {
      name: "Available Credits",
      colKey: "availableCredits",
      ascSorter: objectArrayNumberSorterAsc,
      descSorter: objectArrayNumberSorterDesc,
    },
    {
      name: "Add/Subtract Credits",
      colKey: "credits",
    },
  ]);

function AddSubtractCredits({
  id,
  organization,
  setSuccessMsg,
  toggleSuccessModal,
}) {
  const { addSubtractCredits } = useOrganization();
  const [inputValue, setInputValue] = useState("");

  async function addCredit() {
    const amount = Math.abs(parseFloat(inputValue));

    if (!amount) return;

    const success = await addSubtractCredits(id, amount, "ADD");
    if (success) {
      setSuccessMsg(
        `Added <strong>$${amount}</strong> credit to <strong>${organization}</strong>`,
      );
    } else {
      setSuccessMsg("Failed to Add credit.");
    }
    toggleSuccessModal();
    setInputValue("");
  }

  async function subtractCredit() {
    const amount = Math.abs(parseFloat(inputValue));

    if (!amount) return;

    const success = await addSubtractCredits(id, amount, "SUB");
    if (success) {
      setSuccessMsg(
        `Subtracted <strong>$${amount}</strong> credit from <strong>${organization}</strong>`,
      );
    } else {
      setSuccessMsg("Failed to Subtract credit.");
    }
    toggleSuccessModal();
    setInputValue("");
  }

  function onInputChange(e) {
    let value = e.target.value.replaceAll("-", "");
    let round = false;
    if (value.includes(".")) {
      const decimals = value.split(".")[1];
      round = decimals.length > 2;
    }
    setInputValue(round ? parseFloat(value).toFixed(2) : value);
  }

  return (
    <div className="d-flex align-items-center" style={{ gap: "4px" }}>
      <button
        onClick={subtractCredit}
        type="button"
        className="btn btn-icon btn-ghost-secondary rounded-circle fs-19"
      >
        -
      </button>
      <div style={{ minWidth: "35px", maxWidth: "54px" }}>
        <input
          className="form-control"
          type="number"
          step=".01"
          min="0.01"
          value={inputValue}
          onChange={onInputChange}
          style={{ padding: "5px", textAlign: "center" }}
        />
      </div>
      <button
        onClick={addCredit}
        type="button"
        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle fs-19"
      >
        +
      </button>
    </div>
  );
}

export default function UsersCreditsTab() {
  const { userCreditData } = useOrganization();
  const [allTableData, setAllTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);

  useEffect(() => {
    setAllTableData(userCreditData);
  }, [userCreditData]);

  return (
    <>
      <Card className="top-cards">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">User Credits</h4>
        </CardHeader>

        <CardBody>
          <div className="table-responsive table-card">
            <table
              id="user-credit-table"
              className="table table-borderless table-hover table-nowrap align-middle mb-0"
            >
              <thead className="table-light">
                <tr className="text-muted">
                  {allTableData && (
                    <FilterAndSortHeaders
                      {...{
                        allTableData,
                        filteredTableData,
                        setFilteredTableData,
                        headerData,
                        headerDataObject,
                        initialSortAt,
                        initialFilterAt,
                      }}
                    />
                  )}
                </tr>
              </thead>

              <tbody>
                {(filteredTableData || []).map(
                  (
                    {
                      id,
                      organization,
                      startDate,
                      lastCreditTopupDate,
                      totalCreditsPurchased,
                      availableCredits,
                    },
                    index,
                  ) => (
                    <tr key={index}>
                      <td>{organization}</td>
                      <td>{dateStampToString(startDate)}</td>
                      <td>{dateStampToString(lastCreditTopupDate)}</td>
                      <td>{floatToCurrenctyTxt(totalCreditsPurchased)}</td>
                      <td>{floatToCurrenctyTxt(availableCredits)}</td>
                      <td>
                        <AddSubtractCredits
                          id={id}
                          organization={organization}
                          setSuccessMsg={setSuccessMsg}
                          toggleSuccessModal={toggleSuccessModal}
                        />
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>

      <Modal isOpen={successModalOpen} toggle={toggleSuccessModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0">
            <div
              className="alert alert-success alert-dismissible fade show m-0"
              role="alert"
            >
              <div dangerouslySetInnerHTML={{ __html: successMsg }} />
              <button
                type="button"
                className="btn-close"
                onClick={toggleSuccessModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}
