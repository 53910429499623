import React, { useState, useEffect } from "react";
import * as moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import {
  floatToCurrenctyTxt,
  getMonthlyDateRange,
  getWeeklyDateRange,
  getYearlyDateRange,
} from "../../util";
import { useOrganization } from "../../context/OrganizationContext";

function BalanceOverviewCharts({
  reportsDownloadedSeries,
  expensesSeries,
  setSelectedSeriesExpense,
}) {
  const [series, setSeries] = useState({
    name: "Expenses",
    data: expensesSeries,
  });

  useEffect(() => {
    setSeries({
      name: "Expenses",
      data: expensesSeries,
    });
  }, [reportsDownloadedSeries, expensesSeries]);

  const [colors, setColors] = useState(["#f06548"]);

  let max = series.data.reduce((acc, curr) => Math.max(acc, curr[1]), 0);
  max += max * 0.1;

  const options = {
    chart: {
      height: 290,
      type: "area",
      toolbar: "false",
    },
    legend: {
      show: false,
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          if (series.name === "Expenses") return floatToCurrenctyTxt(value);
          return value.toFixed(0);
        },
      },
      tickAmount: 10,
      min: 0,
      max: max,
    },
    colors: colors,
    fill: {
      colors: colors,
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.6,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={[series]}
        type="area"
        height="290"
        className="apex-charts"
      />

      <div
        className="legendIcons d-flex justify-content-center mt-2"
        style={{ gap: "1rem" }}
      >
        <div
          id="expenses"
          className="d-flex"
          style={{
            gap: "0.4rem",
            cursor: "pointer",
            opacity: series.name === "Expenses" ? 1 : 0.5,
          }}
          onClick={() => {
            setSeries({
              name: "Expenses",
              data: expensesSeries,
            });
            setColors(["#f06548"]);
            setSelectedSeriesExpense(true);
          }}
        >
          <i className="mdi mdi-circle" style={{ color: "#f06548" }}></i>
          Expenses
        </div>
        <div
          id="reports"
          className="d-flex"
          style={{
            gap: "0.4rem",
            cursor: "pointer",
            opacity: series.name === "Reports Downloaded" ? 1 : 0.5,
          }}
          onClick={() => {
            setSeries({
              name: "Reports Downloaded",
              data: reportsDownloadedSeries,
            });
            setColors(["#0ab39c"]);
            setSelectedSeriesExpense(false);
          }}
        >
          <i className="mdi mdi-circle" style={{ color: "#0ab39c" }}></i>
          Reports Downloaded
        </div>
      </div>
    </>
  );
}

export default function CreditOverviewChart() {
  const { userCreditsLogData } = useOrganization();
  const [dateRangeFilter, setDateRangeFilter] = useState("Last 30 Days");
  const [selectedSeriesExpense, setSelectedSeriesExpense] = useState(true);

  const dateRangeLookup = {
    "Last 7 Days": getWeeklyDateRange,
    "Last 30 Days": getMonthlyDateRange,
    "Current Year": getYearlyDateRange,
  };

  const chartSeriesLookup = {
    "Last 7 Days": () => getCategories(7),
    "Last 30 Days": () => getCategories(30),
    "Current Year": () => getCategories(365),
  };

  function getCategories(total) {
    const categories = [];

    let curr = moment();
    categories.push([curr.unix() * 1000, 0]);

    for (let i = 1; i < total; i++) {
      curr = moment(curr).subtract(1, "days");
      categories.unshift([curr.unix() * 1000, 0]);
    }

    return categories;
  }

  const dateRangeDates = dateRangeLookup[dateRangeFilter]();
  const initialSeries = chartSeriesLookup[dateRangeFilter]();

  const {
    totalSpent,
    totalReportsDownloaded,
    reportsDownloadedSeries,
    expensesSeries,
  } = (userCreditsLogData || []).reduce(
    (acc, curr) => {
      if (
        moment(curr.date_of_purchase).isBetween(
          dateRangeDates.startDate,
          undefined,
          undefined,
          "[]",
        )
      ) {
        acc.reportsDownloadedSeries = acc.reportsDownloadedSeries.map((e) => {
          const [date, value] = e;
          if (
            moment(date).format("L") ===
            moment(curr.date_of_purchase).format("L")
          ) {
            const reportsDownloaded = JSON.parse(curr.reports_json).length;
            acc.totalReportsDownloaded += reportsDownloaded;
            return [date, value + reportsDownloaded];
          }
          return e;
        });
        acc.expensesSeries = acc.expensesSeries.map((e) => {
          const [date, value] = e;
          if (
            moment(date).format("L") ===
            moment(curr.date_of_purchase).format("L")
          )
            return [date, value + curr.amount];
          return e;
        });
        acc.totalSpent += curr.amount;
      }
      return acc;
    },
    {
      totalSpent: 0,
      totalReportsDownloaded: 0,
      reportsDownloadedSeries: [...initialSeries],
      expensesSeries: [...initialSeries],
    },
  );

  const topMessage = selectedSeriesExpense ? (
    <h4 className="text-primary">
      {floatToCurrenctyTxt(totalSpent)}
      <span className="text-muted d-inline-block fs-13 align-middle ms-2">
        Total Expense
      </span>
    </h4>
  ) : (
    <h4 className="text-primary">
      {totalReportsDownloaded}
      <span className="text-muted d-inline-block fs-13 align-middle ms-2">
        Total Reports
      </span>
    </h4>
  );

  return (
    <React.Fragment>
      <Col xxl={6}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Expenditure Overview
            </h4>
            <div className="flex-shrink-0">
              <UncontrolledDropdown className="card-header-dropdown">
                <DropdownToggle
                  className="text-reset dropdown-btn"
                  tag="a"
                  role="button"
                >
                  <span
                    className="fw-semibold text-uppercase fs-12"
                    style={{ marginRight: "0.8rem" }}
                  >
                    View by:
                  </span>
                  <span className="text-muted">
                    {dateRangeFilter}
                    <i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    onClick={() => setDateRangeFilter("Last 7 Days")}
                  >
                    Last 7 Days
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setDateRangeFilter("Last 30 Days")}
                  >
                    Last 30 Days
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setDateRangeFilter("Current Year")}
                  >
                    Current Year
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </CardHeader>
          <CardBody className="px-0">
            <ul className="list-inline main-chart text-center mb-0">
              <li className="list-inline-item chart-border-left me-0 border-0">
                {topMessage}
              </li>
            </ul>

            <div id="revenue-expenses-charts" dir="ltr">
              <BalanceOverviewCharts
                {...{
                  reportsDownloadedSeries,
                  expensesSeries,
                  setSelectedSeriesExpense,
                }}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}
