import * as ActionType from "./actionType";

const INIT_STATE = {
  cartItems: [],
  reportItems: [],
  instantPurchasedItems: [],
  purchasedItems: [],
};

function instantPurchase(state, action) {
  return {
    ...state,
    instantPurchasedItems: [...state.instantPurchasedItems, ...action.payload],
    purchasedItems: [...state.purchasedItems, ...action.payload],
  };
}

const ReportSearch = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionType.GET_REPORT_DATA_SUCCESS:
      return {
        ...state,
        reportItems: action.payload,
      };
    case ActionType.GET_REPORT_DATA_FAIL:
      return {
        ...state,
        reportItems: action.payload,
      };
    case ActionType.GET_CART_DATA_SUCCESS:
      return {
        ...state,
        cartItems: action.payload,
      };
    case ActionType.GET_CART_DATA_FAIL:
      return {
        ...state,
        cartItems: action.payload,
      };
    case ActionType.ADD_CART_DATA_SUCCESS:
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    case ActionType.ADD_CART_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ActionType.REMOVE_CART_DATA_SUCCESS:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (cartItem) => cartItem.id.toString() !== action.payload.id.toString(),
        ),
      };
    case ActionType.REMOVE_CART_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // instant purchase
    case ActionType.INSTANT_PURCHASE_CART_DATA_SUCCESS:
      return instantPurchase(state, action);
    case ActionType.INSTANT_PURCHASE_CART_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default ReportSearch;
