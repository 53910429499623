import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Modal,
  ModalBody,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { withRouter, Link, useHistory } from "react-router-dom";
import { ReactComponent as BrandIcon } from "../../assets/images/TRF-LOGO-RGB_HORIZONTAL-INV.svg";
import * as api from "../../api";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../../context/AuthContext";
import { useEffect } from "react";

async function verifyOrganization(user_token, organization_name) {
  const sendData = { organization_name };
  const [data, status] = await api.verifyOrganization(user_token, sendData);
  return [data.data, status];
}

function Login(props) {
  const { login, logout } = useAuth();
  const history = useHistory();

  const [errorMsg, setErrorMsg] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      organization: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
      organization: Yup.string().required("Please Enter Your Organization"),
    }),

    onSubmit: async (values) => {
      let idTokenResult = {};
      try {
        const response = await login(values.email, values.password);
        idTokenResult = await response.user.getIdTokenResult();
      } catch (e) {
        if (e.code === "auth/user-not-found") {
          setErrorMsg(`User <strong>${values.email}</strong> not found.`);
        } else if (e.code === "auth/wrong-password") {
          setErrorMsg("Wrong password. Please try again.");
        } else {
          console.log(e.code);
          setErrorMsg("Something went wrong. Please try again.");
        }
        toggleErrorModal();
        return;
      }
      const [verified, success] = await verifyOrganization(
        idTokenResult.token,
        values.organization,
      );

      if (verified) {
        history.push("/");
      } else {
        logout();
        setErrorMsg(
          `Organization <strong>${values.organization}</strong> not found.`,
        );
        toggleErrorModal();
      }
    },
  });

  return (
    <>
      <ParticlesAuth>
        <div className="auth-page-content">
          <MetaTags>
            <title>SignIn | The Report Finder</title>
          </MetaTags>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <h1 style={{ color: "white" }}>
                        <BrandIcon style={{ width: "max(450px, 50vmin)" }} />
                      </h1>
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">Search & Find Reports</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to The Report Finder.
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            {/* <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button> */}
                          </div>
                        </div>

                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="organization-input"
                          >
                            Organization
                          </Label>
                          <div className="position-relative auth-org-inputgroup mb-3">
                            <Input
                              name="organization"
                              value={validation.values.organization || ""}
                              type="organization"
                              className="form-control pe-5"
                              placeholder="Enter Organization"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.organization &&
                                validation.errors.organization
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.organization &&
                            validation.errors.organization ? (
                              <FormFeedback type="invalid">
                                {validation.errors.organization}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        {/* <div className="form-check">
                          <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                          <Label className="form-check-label" htmlFor="auth-remember-check">
                            Remember me
                          </Label>
                        </div> */}

                        <div className="mt-4">
                          <Button
                            color="primary"
                            className="w-100"
                            type="submit"
                          >
                            Sign In
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>

      <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0">
            <div
              className="alert alert-danger alert-dismissible fade show m-0"
              role="alert"
            >
              <div dangerouslySetInnerHTML={{ __html: errorMsg }} />
              <button
                type="button"
                className="btn-close"
                onClick={toggleErrorModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}

export default withRouter(Login);
