import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Modal, ModalBody, Row, Table } from "reactstrap";
import { FilterAndSortHeaders, createHeaderData } from "../../common";
import { useOrganization } from "../../context/OrganizationContext";
import { objectArrayStringSorterAsc, objectArrayStringSorterDesc, objectArrayNumberSorterAsc, objectArrayNumberSorterDesc, dateStampToString } from "../../util";

const { headerData, headerDataObject, initialSortAt, initialFilterAt } = createHeaderData([
  {
    name: "Name",
    colKey: "user_name",
    ascSorter: objectArrayStringSorterAsc,
    descSorter: objectArrayStringSorterDesc,
  },
  {
    name: "Date of Joining",
    colKey: "user_date_of_joining",
    ascSorter: objectArrayNumberSorterAsc,
    descSorter: objectArrayNumberSorterDesc,
  },
  {
    name: "Reports Purchased",
    colKey: "user_reports_purchased",
    ascSorter: objectArrayNumberSorterAsc,
    descSorter: objectArrayNumberSorterDesc,
  },
  {
    name: "OCR Runs",
    colKey: "user_ocr_runs",
    ascSorter: objectArrayNumberSorterAsc,
    descSorter: objectArrayNumberSorterDesc,
  },
  {
    name: "Credits Spent",
    colKey: "user_credit_spent",
    ascSorter: objectArrayNumberSorterAsc,
    descSorter: objectArrayNumberSorterDesc,
  },
  {
    name: "Last Purchased Date",
    colKey: "user_last_purchased_date",
    ascSorter: objectArrayNumberSorterAsc,
    descSorter: objectArrayNumberSorterDesc,
  },
]);

export default function OrganizationUsersTable({ members }) {
  const { deleteUser, updateUser, resetUserPassword } = useOrganization();

  const [allTableData, setAllTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [userUpdateFormData, setUserUpdateFormDate] = useState({
    email: "",
    mobile: "",
  });

  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
  const toggleMemberModal = () => setMemberModalOpen(!memberModalOpen);

  function onRowClick(member) {
    setUserUpdateFormDate({
      email: member.user_email,
      mobile: member.user_mobile,
    });
    setSelectedMember(member);
    setMemberModalOpen(true);
  }

  async function deleteSelectedUser() {
    const successful = await deleteUser(selectedMember.user_id);
    if (successful) {
      setSuccessMsg(`User <strong> ${selectedMember.user_name} </strong> deleted successfully`);
      toggleMemberModal();
      toggleSuccessModal();
    }
  }

  async function updateSelectedUser(e) {
    e.preventDefault();

    const successful = await updateUser({
      user_id: selectedMember.user_id,
      user_email: userUpdateFormData.email,
      user_mobile: userUpdateFormData.mobile,
    });

    if (successful) {
      setSuccessMsg(`User <strong> ${selectedMember.user_name} </strong> updated successfully`);
      toggleMemberModal();
      toggleSuccessModal();
    }
  }

  async function resetPassword() {
    const successful = await resetUserPassword(selectedMember.user_id);

    if (successful) {
      setSuccessMsg(`Reset mail sent for <strong> ${selectedMember.user_name} </strong>`);
      toggleMemberModal();
      toggleSuccessModal();
    }
  }

  useEffect(() => {
    console.log(members);
    setAllTableData(members);
  }, [members]);

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col lg={12}>
              <div className="table-responsive downloaded-tables-container">
                <Table className="table-borderless align-middle mb-0 downloaded-tables">
                  <thead className="table-light">
                    <tr>
                      <FilterAndSortHeaders
                        {...{
                          allTableData,
                          filteredTableData,
                          setFilteredTableData,
                          headerData,
                          headerDataObject,
                          initialSortAt,
                          initialFilterAt,
                        }}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTableData.map((member, key) => {
                      const { user_name, user_date_of_joining, user_reports_purchased, user_credit_spent, user_last_purchased_date, user_ocr_runs } = member;
                      return (
                        <tr key={key}>
                          <td onClick={() => onRowClick(member)} style={{ cursor: "pointer" }}>
                            {user_name}
                          </td>
                          <td>{dateStampToString(user_date_of_joining)}</td>
                          <td>{user_reports_purchased}</td>
                          <td>{user_ocr_runs}</td>
                          <td>${user_credit_spent > 999 ? (user_credit_spent / 1000).toFixed(2) + "k" : user_credit_spent}</td>
                          <td>{user_last_purchased_date ? dateStampToString(user_last_purchased_date) : "---"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={memberModalOpen} toggle={toggleMemberModal} centered>
        <div className="modal-content">
          <div className="modal-header p-3" style={{ background: "#3577f1" }}>
            <h5 className="modal-title" style={{ color: "white" }} id="myModalLabel">
              {selectedMember?.user_name}
            </h5>
            <button onClick={toggleMemberModal} type="button" className="btn-close  btn-close-white" aria-label="Close"></button>
          </div>
          <ModalBody>
            <form onSubmit={updateSelectedUser}>
              <div className="table-responsive">
                <table id="report-table" className="table table-bordered table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Email</td>
                      <td>
                        <input
                          className="form-control"
                          name="email"
                          type="email"
                          value={userUpdateFormData.email}
                          onChange={(e) =>
                            setUserUpdateFormDate({
                              ...userUpdateFormData,
                              email: e.target.value,
                            })
                          }
                          placeholder="Email"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Mobile Number</td>
                      <td>
                        <input
                          className="form-control"
                          name="mobile"
                          type="tel"
                          onChange={(e) =>
                            setUserUpdateFormDate({
                              ...userUpdateFormData,
                              mobile: e.target.value,
                            })
                          }
                          value={userUpdateFormData.mobile}
                          placeholder="Mobile"
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-4 w-100 d-flex flex-row-reverse" style={{ gap: "0.6rem" }}>
                <button onClick={deleteSelectedUser} type="button" className="btn btn-danger" data-bs-dismiss="modal">
                  Delete
                </button>
                <button onClick={resetPassword} type="button" className="btn btn-warning" data-bs-dismiss="modal">
                  Reset Password
                </button>
                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">
                  Update
                </button>
              </div>
            </form>
          </ModalBody>
        </div>
      </Modal>

      <Modal isOpen={successModalOpen} toggle={toggleSuccessModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0">
            <div className="alert alert-success alert-dismissible fade show m-0" role="alert">
              <div dangerouslySetInnerHTML={{ __html: successMsg }} />
              <button type="button" className="btn-close" onClick={toggleSuccessModal} aria-label="Close"></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}
