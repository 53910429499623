import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useOrganization } from "../../context/OrganizationContext";

const TopCreditLink = () => {
  const { userCreditBalance } = useOrganization();
  const [credit, setCredit] = useState(0);

  useEffect(() => {
    setCredit(
      userCreditBalance > 999
        ? `${(userCreditBalance / 1000).toFixed(2)}k`
        : userCreditBalance,
    );
  }, [userCreditBalance]);

  return (
    <Link
      to="/credit-history"
      type="button"
      tag="button"
      className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
    >
      <i className="bx bxs-credit-card fs-22"></i>
      <span className="position-absolute fs-10 topbar-badge translate-middle badge rounded-pill bg-danger">
        {credit}
        <span className="visually-hidden">Credit Points</span>
      </span>
    </Link>
  );
};

export default TopCreditLink;
