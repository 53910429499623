import React, { useState, useEffect } from "react";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Col, Row, Label } from "reactstrap";
import ReportTable from "./ReportTable";
import { useOrganization } from "../../context/OrganizationContext";
import "./ReportSearchSelections.css";

export default function ReportSearchSelections() {
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 30);
  const maxDate = new Date();

  const defaultDate = [minDate, maxDate];

  const { statesData, getOrganizationsData } = useOrganization();
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [selectedState, setSelectedState] = useState("");
  const [selectedJurisdiction, setSelectedJurisdiction] = useState([]);
  const [stateOptions, setStateOptions] = useState({});
  const [juryOptions, setJuryOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  function setStateAndJurisdiction() {
    if (statesData) {
      const data = statesData?.reduce(
        (acc, { state, jurisdiction, active }) => {
          if (active) {
            if (!acc[state]) acc[state] = [];
            acc[state].push([jurisdiction]);
          }
          return acc;
        },
        {},
      );

      setStateOptions(data);
      setLoading(false);
    }
  }

  useEffect(setStateAndJurisdiction, [statesData]);
  useEffect(getOrganizationsData, []);

  useEffect(() => {
    if (selectedState) {
      const options = stateOptions[selectedState]
        ?.map((jurisdiction) => {
          jurisdiction = jurisdiction[0];
          return {
            value: jurisdiction,
            label: jurisdiction,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

      options.unshift({ value: "Select All", label: "Select All" });

      setJuryOptions(options);
    }
  }, [selectedState, stateOptions]);

  const multiselectLabel = selectedJurisdiction.length
    ? `${selectedJurisdiction.length} Jurisdictions Selected`
    : "Select Jurisdictions...";

  return (
    <>
      <Row>
        <Col>
          <Label>Crash Date Range [Only last 31 days searchable]</Label>
          <Flatpickr
            className="form-control"
            id="event-start-date"
            name="defaultDate"
            placeholder="Select Date..."
            options={{
              mode: "range",
              dateFormat: "m-d-Y",
              maxDate: new Date(),
              minDate,
              defaultDate,
            }}
            onChange={(dates) => {
              setSelectedDate(dates);
            }}
          />
        </Col>
        <Col>
          <Label>State</Label>
          <Select
            isDisabled={loading}
            className={loading ? "show-loader" : ""}
            placeholder={loading ? "" : "Select State..."}
            name="state"
            value={
              selectedState
                ? { value: selectedState, label: selectedState }
                : null
            }
            options={Object.keys(stateOptions)
              .sort((a, b) => a.localeCompare(b))
              .map((state) => ({ value: state, label: state }))}
            onChange={({ value }) => {
              setSelectedState(value);
              setSelectedJurisdiction([]);
            }}
          />
        </Col>
        <Col>
          <Label>Jurisdiction</Label>
          <Select
            className={loading ? "show-loader" : ""}
            isDisabled={loading || !selectedState}
            placeholder={loading ? "" : "Select Jurisdictions..."}
            value={{ value: multiselectLabel, label: multiselectLabel }}
            closeMenuOnSelect={false}
            onChange={({ value, label }) => {
              let options = juryOptions.filter(
                (option) =>
                  option.value !== "Select All" &&
                  option.value !== "Deselect All",
              );

              if (value === "Select All") {
                options = options
                  .map((option) => ({
                    value: option.value,
                    label: "✔ " + option.value,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label));
              } else if (value === "Deselect All") {
                options = options
                  .map((option) => ({
                    value: option.value,
                    label: option.value,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label));
              } else {
                options = options
                  .map((option) => {
                    if (option.value === value) {
                      if (option.label === option.value) {
                        return {
                          value: option.value,
                          label: "✔ " + option.label,
                        };
                      }
                      return { value: option.value, label: option.value };
                    }
                    return option;
                  })
                  .sort((a, b) => a.label.localeCompare(b.label));
              }

              const allSelected = options.every((option) =>
                option.label.startsWith("✔ "),
              );

              if (allSelected) {
                options.unshift({
                  value: "Deselect All",
                  label: "Deselect All",
                });
              } else {
                options.unshift({ value: "Select All", label: "Select All" });
              }

              setJuryOptions(options);
              setSelectedJurisdiction(
                options
                  .filter((option) => option.label === "✔ " + option.value)
                  .map((option) => option.value),
              );
            }}
            options={juryOptions}
            name="jurisdiction"
          />
        </Col>
      </Row>
      <ReportTable {...{ selectedDate, selectedState, selectedJurisdiction }} />
    </>
  );
}
