import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const Navdata = () => {
  const history = useHistory();
  const { userIsAdmin } = useAuth();
  //state data
  const [isSearch, setIsSearch] = useState(false);
  const [isDownloadedReports, setIsDownloadedReports] = useState(false);
  const [isAdminOverview, setIsAdminOverview] = useState(false);
  const [isOcrReports, setIsOcrReports] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Search");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Admin Overview") {
      setIsAdminOverview(false);
    }
    if (iscurrentState !== "Search") {
      setIsSearch(false);
    }
    if (iscurrentState !== "Downloaded Reports") {
      setIsDownloadedReports(false);
    }
    if (iscurrentState !== "OCR Reports") {
      setIsOcrReports(false);
    }
  }, [history, iscurrentState, isSearch, isAdminOverview, isDownloadedReports]);

  const menuItems = [
    {
      id: "search",
      label: "Search",
      icon: "las la-search",
      link: "/search",
      stateVariables: isSearch,
      click: function (e) {
        e.preventDefault();
        setIsSearch(!isSearch);
        setIscurrentState("Search");
        updateIconSidebar(e);
      },
    },
  ];

  if (!userIsAdmin) {
    menuItems.push({
      id: "downloadedReports",
      label: "Downloaded Reports",
      icon: "las la-file-download",
      link: "/downloaded-reports",
      stateVariables: isDownloadedReports,
      click: function (e) {
        e.preventDefault();
        setIsDownloadedReports(!isDownloadedReports);
        setIscurrentState("Downloaded Reports");
        updateIconSidebar(e);
      },
    });
  }

  if (userIsAdmin) {
    menuItems.push({
      id: "ocrReports",
      label: "OCR Reports",
      icon: "las la-file-pdf",
      link: "/ocr-reports",
      stateVariables: isOcrReports,
      click: function (e) {
        e.preventDefault();
        setIsDownloadedReports(!isOcrReports);
        setIscurrentState("OCR Reports");
        updateIconSidebar(e);
      },
    });
    menuItems.push({
      id: "adminOverview",
      label: "Admin",
      icon: "las la-user-tie",
      link: "/admin-overview",
      stateVariables: isAdminOverview,
      click: function (e) {
        e.preventDefault();
        setIsDownloadedReports(!isAdminOverview);
        setIscurrentState("Admin Overview");
        updateIconSidebar(e);
      },
    });
  } else {
    menuItems.push({
      id: "ocrReports",
      label: "OCR Reports",
      icon: "las la-file-pdf",
      link: "/ocr-reports",
      stateVariables: isOcrReports,
      click: function (e) {
        e.preventDefault();
        setIsDownloadedReports(!isOcrReports);
        setIscurrentState("OCR Reports");
        updateIconSidebar(e);
      },
    });
  }

  return <>{menuItems}</>;
};
export default Navdata;
