import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Modal, ModalBody } from "reactstrap";
import { getConditionalTitleCase, objectArrayStringSorterAsc, objectArrayStringSorterDesc, stringOptionsSorter } from "../../util";
import { FilterAndSortHeaders, createHeaderData } from "../../common";
import { useOrganization } from "../../context/OrganizationContext";
import { useAuth } from "../../context/AuthContext";
import { getOcrPageStateJury, toggleOcrPermission, getOcrData, updateOcrData } from "../../api";

export default function OCRPermissionsTab() {
  const { getUserToken } = useAuth();
  const { organizationsData, getOrganizationsData } = useOrganization();

  const [allTableData, setAllTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [headerData, setHeaderData] = useState([]);
  const [headerDataObject, setHeaderDataObject] = useState({});
  const [initialSortAt, setInitialSortAt] = useState({});
  const [initialFilterAt, setInitialFilterAt] = useState({});

  const [allJurisdictionsOrWebsites, setAllJurisdictionsOrWebsites] = useState([]);

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({});

  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);

  async function updateOcrDataCallback({ name, price, file_upload_limit }) {
    if (!price || !file_upload_limit) {
      setModalMessage({
        class: "alert-danger",
        message: "Please enter a valid price and file upload limit.",
      });

      toggleSuccessModal();

      return;
    }
    const userToken = await getUserToken();
    const [data, status] = await updateOcrData(userToken, {
      name,
      price,
      file_upload_limit,
    });

    if (status === 200) {
      setModalMessage({
        class: "alert-success",
        message: "OCR data updated successfully!",
      });
      toggleSuccessModal();
    }
  }

  async function updateHeaders() {
    setLoading(true);
    const userToken = await getUserToken();
    const [[data, status], [ocrData, ocrStatus]] = await Promise.all([getOcrPageStateJury(userToken), getOcrData(userToken)]);

    let jurisdictionsOrWebsites = [];

    for (const [key, value] of Object.entries(data.jurisdictions)) {
      jurisdictionsOrWebsites = jurisdictionsOrWebsites.concat(value);
    }

    jurisdictionsOrWebsites = jurisdictionsOrWebsites.concat(data.websites);

    const headerInfo = createHeaderData([
      {
        name: "Organizations",
        colKey: "organization",
        ascSorter: objectArrayStringSorterAsc,
        descSorter: objectArrayStringSorterDesc,
        optionsSorter: stringOptionsSorter,
      },
      ...jurisdictionsOrWebsites.map((jOrW) => {
        const lower_name = jOrW.toLowerCase().split(" ").join("_");
        const { price, file_upload_limit } = ocrData[lower_name];
        return {
          name: getConditionalTitleCase(jOrW),
          colKey: jOrW,
          ocrData: { name: lower_name, price, file_upload_limit, updateOcrDataCallback },
        };
      }),
    ]);

    setAllJurisdictionsOrWebsites(jurisdictionsOrWebsites);
    setHeaderData(headerInfo.headerData);
    setHeaderDataObject(headerInfo.headerDataObject);
    setInitialSortAt(headerInfo.initialSortAt);
    setInitialFilterAt(headerInfo.initialFilterAt);

    setLoading(false);
  }

  useEffect(() => updateHeaders(), []);

  useEffect(() => {
    if (!organizationsData || allJurisdictionsOrWebsites.length === 0) return;

    const newTableData = {};

    for (const [key, value] of Object.entries(organizationsData)) {
      for (const juryOrWebsite of allJurisdictionsOrWebsites) {
        if (!newTableData[value.name]) newTableData[value.name] = { id: value.id, organization: value.name };

        newTableData[value.name] = {
          ...newTableData[value.name],
          [juryOrWebsite]: value.ocr_permissions[juryOrWebsite] || false,
        };
      }
    }

    const finalTableData = [];

    for (const [key, value] of Object.entries(newTableData)) {
      finalTableData.push(value);
    }

    setAllTableData(finalTableData);
  }, [organizationsData, allJurisdictionsOrWebsites]);

  async function updatePermissions(e, orgId, juryOrWebsite) {
    const [msg, status] = await toggleOcrPermission(await getUserToken(), {
      organization_id: orgId,
      juryOrWebsite: juryOrWebsite,
    });

    if (msg === "Success") {
      const newTableData = [...allTableData];
      for (let item of newTableData) {
        if (item.id === orgId) {
          item[juryOrWebsite] = !item[juryOrWebsite];
          break;
        }
      }
      setAllTableData(newTableData);
      getOrganizationsData();
      setModalMessage({
        class: "alert-success",
        message: "Permissions updated successfully!",
      });
    } else {
      setModalMessage({
        class: "alert-danger",
        message: "Error updating permissions. Please try again.",
      });
    }
    toggleSuccessModal();
  }

  return (
    <>
      <Card className="top-cards">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">OCR Permissions</h4>
          <div className="flex-shrink-0"></div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive table-card">
            {!loading && (
              <table id="user-history-table" className="table table-borderless table-hover table-nowrap align-middle mb-0">
                <thead className="table-light">
                  <tr className="text-muted">
                    {allTableData && (
                      <FilterAndSortHeaders
                        {...{
                          allTableData,
                          filteredTableData,
                          setFilteredTableData,
                          headerData,
                          headerDataObject,
                          initialSortAt,
                          initialFilterAt,
                        }}
                      />
                    )}
                  </tr>
                </thead>
                <tbody>
                  {(filteredTableData || []).map((item, index) => (
                    <tr key={index}>
                      <td>{item.organization}</td>
                      {allJurisdictionsOrWebsites.map((juryOrWebsite, i) => (
                        <td key={i}>
                          <div className="form-check form-switch">
                            <input onChange={(e) => updatePermissions(e, item.id, juryOrWebsite)} type="checkbox" className="form-check-input" checked={item[juryOrWebsite]} role="switch" />
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </CardBody>
      </Card>

      <Modal isOpen={successModalOpen} toggle={toggleSuccessModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0">
            <div className={`alert ${modalMessage.class} alert-dismissible fade show m-0`} role="alert">
              <div dangerouslySetInnerHTML={{ __html: modalMessage.message }} />
              <button type="button" className="btn-close" onClick={toggleSuccessModal} aria-label="Close"></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}
