import React, { useEffect } from "react";
import { Row, Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import TopWidget from "./TopWidget";
import CreditOverviewChart from "./CreditOverviewChart";
import CreditHistoryTable from "./CreditHistoryTable";
import { useOrganization } from "../../context/OrganizationContext";

export default function Index() {
  const { getUserCreditLogData, getOrganizationsData } = useOrganization();

  useEffect(() => {
    getOrganizationsData();
    getUserCreditLogData();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <MetaTags>
          <title>Credit History</title>
        </MetaTags>
        <BreadCrumb title="Credit History" pageTitle="History" />
        <Row>
          <TopWidget />
        </Row>
        <Row>
          <CreditHistoryTable />
          <CreditOverviewChart />
        </Row>
      </Container>
    </div>
  );
}
