import { call, put, takeEvery } from "redux-saga/effects";

// Ecoomerce Redux States
import {
  GET_CART_DATA,
  ADD_CART_DATA,
  REMOVE_CART_DATA,
  GET_REPORT_DATA,
  INSTANT_PURCHASE_CART_DATA,
} from "./actionType";

import {
  getReportDataSuccess,
  getReportDataFail,
  getCartDataFail,
  getCartDataSuccess,
  addCartDataFail,
  addCartDataSuccess,
  removeCartDataFail,
  removeCartDataSuccess,
  instantPurchaseFail,
  instantPurchaseSuccess,
} from "./action";

//Include Both Helper File with needed methods
import {
  getCartData as getCartDataApi,
  addCartData as addCartDataApi,
  removeCartData as removeCartDataApi,
  // getReportData as getReportDataApi,
  instantCartPurchase as instantCartPurchaseApi,
} from "../../helpers/fakebackend_helper";

import { getAllReports } from "../../api";

import * as moment from "moment";

function* getReportDataMiddle({ payload: sendData }) {
  if (sendData?.token) {
    let {
      token,
      setProgress,
      organization_id,
      state,
      jurisdictions,
      start_date,
      end_date,
    } = sendData;
    start_date = moment(start_date).format("YYYY-MM-DD");
    end_date = moment(end_date).format("YYYY-MM-DD");
    try {
      const response = yield call(getAllReports, {
        token,
        setProgress,
        data: { organization_id, state, jurisdictions, start_date, end_date },
      });
      yield put(getReportDataSuccess(response));
    } catch (error) {
      yield put(getReportDataFail(error));
    }
  }
}

function* getCartDataMiddle() {
  try {
    const response = yield call(getCartDataApi);
    yield put(getCartDataSuccess(response));
  } catch (error) {
    yield put(getCartDataFail(error));
  }
}

function* addCartDataMiddle({ payload: cartItem }) {
  try {
    const response = yield call(addCartDataApi, cartItem);
    yield put(addCartDataSuccess(response));
  } catch (error) {
    yield put(addCartDataFail(error));
  }
}

function* removeCartDataMiddle({ payload: cartItem }) {
  try {
    const response = yield call(removeCartDataApi, cartItem);
    yield put(removeCartDataSuccess(response));
  } catch (error) {
    yield put(removeCartDataFail(error));
  }
}

function* instantCartPurchaseMiddle({ payload: cartItems }) {
  try {
    const response = yield call(instantCartPurchaseApi, cartItems);
    yield put(instantPurchaseSuccess(response));
  } catch (error) {
    yield put(instantPurchaseFail(error));
  }
}

function* cartSaga() {
  yield takeEvery(GET_CART_DATA, getCartDataMiddle);
  yield takeEvery(ADD_CART_DATA, addCartDataMiddle);
  yield takeEvery(REMOVE_CART_DATA, removeCartDataMiddle);
  yield takeEvery(GET_REPORT_DATA, getReportDataMiddle);
  yield takeEvery(INSTANT_PURCHASE_CART_DATA, instantCartPurchaseMiddle);
}

export default cartSaga;
