// Actions

// get reports
export const GET_REPORT_DATA = "GET_REPORT_DATA";
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS";
export const GET_REPORT_DATA_FAIL = "GET_REPORT_DATA_FAIL";

// get cart data
export const GET_CART_DATA = "GET_CART_DATA";
export const GET_CART_DATA_SUCCESS = "GET_CART_DATA_SUCCESS";
export const GET_CART_DATA_FAIL = "GET_CART_DATA_FAIL";
// add cart data
export const ADD_CART_DATA = "ADD_CART_DATA";
export const ADD_CART_DATA_SUCCESS = "ADD_CART_DATA_SUCCESS";
export const ADD_CART_DATA_FAIL = "ADD_CART_DATA_FAIL";
// remove cart data
export const REMOVE_CART_DATA = "REMOVE_CART_DATA";
export const REMOVE_CART_DATA_SUCCESS = "REMOVE_CART_DATA_SUCCESS";
export const REMOVE_CART_DATA_FAIL = "REMOVE_CART_DATA_FAIL";

// instant purchase
export const INSTANT_PURCHASE_CART_DATA = "INSTANT_PURCHASE_CART_DATA";
export const INSTANT_PURCHASE_CART_DATA_SUCCESS =
  "INSTANT_PURCHASE_CART_DATA_SUCCESS";
export const INSTANT_PURCHASE_CART_DATA_FAIL =
  "INSTANT_PURCHASE_CART_DATA_FAIL";
