import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import * as api from "../api";

const OrganizationContext = React.createContext();

export function useOrganization() {
  return useContext(OrganizationContext);
}

function digestOrganizationData(json) {
  const data = json.data.reduce((acc, curr) => {
    const { user_id, user_name, user_email, user_mobile, user_organization_id, user_date_of_joining, user_reports_purchased, user_credit_spent, user_ocr_runs, user_last_purchased_date } = curr;

    if (!acc[curr.id]) {
      acc[curr.id] = {
        id: curr.id,
        name: curr.name,
        start_date: curr.start_date,
        credit_balance: curr.credit_balance,
        last_topup_date: curr.last_topup_date,
        total_topup_amount: curr.total_topup_amount,
        disabled: curr.disabled,
        ohio_crash_app_user: curr.ohio_crash_app_user,
        metropolitan_nashville_mail_user: curr.metropolitan_nashville_mail_user,
        ocr_permissions: curr.ocr_permissions,
        members: user_id
          ? [
              {
                user_id,
                user_name,
                user_email,
                user_mobile,
                user_organization_id,
                user_organization_name: curr.name,
                user_date_of_joining,
                user_reports_purchased,
                user_credit_spent,
                user_ocr_runs,
                user_last_purchased_date,
              },
            ]
          : [],
      };
    } else {
      acc[curr.id].members.push({
        user_id,
        user_name,
        user_email,
        user_mobile,
        user_organization_id,
        user_organization_name: curr.name,
        user_date_of_joining,
        user_reports_purchased,
        user_credit_spent,
        user_ocr_runs,
        user_last_purchased_date,
      });
    }

    return acc;
  }, {});

  return data;
}

export function OrganizationProvider({ children }) {
  const { currentUser, userOrg, userIsAdmin, getUserToken, userNamespace } = useAuth();

  const [organizationsData, setOrganizationsData] = useState();
  const [userOrganizationData, setUserOrganizationData] = useState();
  const [userHistoryData, setUserHistoryData] = useState([]);
  const [userCreditData, setUserCreditData] = useState();
  const [statesData, setStatesData] = useState();
  const [userCreditBalance, setUserCreditBalance] = useState(0);
  const [userCreditsLogData, setUserCreditsLogData] = useState();
  const [userOcrLogData, setUserOcrLogData] = useState();
  const [topupData, setTopupData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [purchaseHistory, setPurchseHistory] = useState([]);

  function updateOrganizationData(data) {
    setOrganizationsData(digestOrganizationData(data));
  }

  async function addMember({ memberOrganizationId, memberOrganizationName, memberName, memberEmail, memberMobile }) {
    let memberDateOfJoining = new Date();
    memberDateOfJoining = memberDateOfJoining.toLocaleDateString("en-US");

    const sendData = {
      memberOrganizationId,
      memberDateOfJoining,
      memberName,
      memberEmail,
      memberMobile,
      memberOrganizationName,
    };

    const [data, status] = await api.addMember(await getUserToken(), sendData);
    updateOrganizationData(data);

    return status;
  }

  async function getOcrReportData(state, juryOrWebsite, filesData) {
    const [data, status] = await api.getOcrReportData(await getUserToken(), {
      state,
      juryOrWebsite,
      filesData,
      namespace: userNamespace,
    });
    return data;
  }

  async function getAllOrganization() {
    const [data, status] = await api.getOrganizations(await getUserToken());
    updateOrganizationData(data);
  }

  async function addOrganization(organizationName) {
    let startDate = new Date();
    startDate = startDate.toLocaleDateString("en-US");
    const sendData = { organizationName, startDate };
    const [data, status] = await api.addOrganization(await getUserToken(), sendData);
    updateOrganizationData(data);

    return status;
  }

  async function updateUser({ user_id, user_email, user_mobile }) {
    const sendData = { user_id, user_email, user_mobile };
    const [data, status] = await api.updateUser(await getUserToken(), sendData);
    updateOrganizationData(data);

    return status;
  }

  async function deleteUser(user_id) {
    const sendData = { user_id };
    const [data, status] = await api.deleteUser(await getUserToken(), sendData);
    updateOrganizationData(data);

    return status;
  }

  async function getUserOrganization() {
    const [data, status] = await api.getUserOrganization(await getUserToken());
    setUserOrganizationData(data.data);
    return status;
  }

  async function getStatesData() {
    const [data, status] = await api.getStatesData(await getUserToken());
    setStatesData(data.data);
    return status;
  }

  async function getStateLimits() {
    const [data, status] = await api.getStateLimits(await getUserToken());
    return data.data;
  }

  async function setStateLimits(state, limit) {
    const sendData = { state, limit };
    const [data, status] = await api.setStateLimits(await getUserToken(), sendData);
    return status;
  }

  async function toggleState(state, active) {
    const [data, status] = await api.toggleState(await getUserToken(), {
      state,
      active,
    });
    return status;
  }

  async function toggleJurisdiction(state, jurisdiction, active) {
    const [data, status] = await api.toggleJurisdiction(await getUserToken(), {
      state,
      jurisdiction,
      active,
    });
    return status;
  }

  async function purchaseItems(items) {
    let date_of_purchase = new Date();
    date_of_purchase = date_of_purchase.toLocaleDateString("en-US");

    const totalPrice = items.reduce((acc, curr) => acc + curr.Price, 0);

    if (totalPrice > userCreditBalance) {
      return [false, "Insufficient balance"];
    }

    const reports_json = JSON.stringify(
      items.map((item) => {
        const { state, jurisdiction } = item;
        const report_number = item["Report Number"];
        const amount = item["Price"];
        const crash_date = item["Crash Date"];
        const id = item.id;
        const agency_ori = item.agency_ori;
        const parties_details = item.parties_details;
        const result = {
          id,
          state,
          jurisdiction,
          agency_ori,
          crash_date,
          report_number,
          parties_details,
          amount,
        };

        return result;
      })
    );

    const sendData = {
      organization_id: userOrg,
      user_id: currentUser.uid,
      reports_json,
      date_of_purchase,
    };

    const [data, status] = await api.purchaseReports(await getUserToken(), sendData);

    if (data[0] === "Insufficient balance") {
      return [false, "Insufficient balance"];
    } else {
      setOrganizationsData(digestOrganizationData({ data }));
      return [true, "ok"];
    }
  }

  async function getUserCreditLogData() {
    const sendData = { user_id: currentUser.uid };
    const [data, status] = await api.getUserCreditLogData(await getUserToken(), sendData);
    setUserCreditsLogData(data.data);
    return status;
  }

  async function getUserOcrLogData() {
    const sendData = { user_id: currentUser.uid };
    const [data, status] = await api.getUserOcrLogData(await getUserToken(), sendData);
    setUserOcrLogData(data.data);
    return status;
  }

  async function getUserLogs() {
    getUserCreditLogData();
    getUserOcrLogData();
  }

  async function addSubtractCredits(organization_id, amount, to) {
    let topup_date = new Date();
    topup_date = topup_date.toLocaleDateString("en-US");
    const sendData = {
      organization_id,
      amount,
      to,
      topup_date,
    };
    const [data, status] = await api.addSubtractCredit(await getUserToken(), sendData);
    updateOrganizationData(data);
    return status;
  }

  async function getTopupData() {
    const [data, status] = await api.getTopupData(await getUserToken());
    setTopupData(data.data);
    return status;
  }

  async function getReportData() {
    const [data, status] = await api.getReportData(await getUserToken());
    setReportData(data.data);
    return status;
  }

  async function disabledOrEnableOrganization(organization_id) {
    const [data, status] = await api.disabledOrEnableOrganization(await getUserToken(), { organization_id });
    updateOrganizationData(data);
    return status;
  }

  async function resetUserPassword(user_id, new_password = null) {
    const [data, status] = await api.resetUserPassword(await getUserToken(), {
      user_id,
      new_password,
    });
    return status;
  }

  async function toggleOhioCrashApp(organization_id) {
    const [data, status] = await api.toggleOhioCrashApp(await getUserToken(), {
      organization_id,
    });
    updateOrganizationData(data);
    return status;
  }

  async function toggleNashvilleMailUser(organization_id) {
    const [data, status] = await api.toggleNashvilleMailUser(await getUserToken(), { organization_id });
    updateOrganizationData(data);
    return status;
  }

  async function getPurchaseHistory() {
    const [data, status] = await api.getPurchaseHistory(await getUserToken(), {
      organization_id: userOrg,
    });
    setPurchseHistory(data.data);
    return data.data;
  }

  async function downloadReport(report_id) {
    const [data, status] = await api.downloadReport(await getUserToken(), {
      report_id,
    });
    return data.data;
  }
  async function downloadReports(report_ids) {
    const [data, status] = await api.downloadReports(await getUserToken(), {
      report_ids,
    });
    return data.data;
  }

  useEffect(() => {
    if (organizationsData) {
      // user credit balance
      setUserCreditBalance(organizationsData[userOrg]?.credit_balance);

      // user credit data
      const creditData = Object.entries(organizationsData).map(([key, value]) => {
        const { id, name, start_date, credit_balance, last_topup_date, total_topup_amount } = value;
        return {
          id,
          organization: name,
          startDate: start_date,
          lastCreditTopupDate: last_topup_date,
          totalCreditsPurchased: total_topup_amount,
          availableCredits: credit_balance,
        };
      });

      setUserCreditData(creditData);

      // user history data
      const historyData = Object.entries(organizationsData).map(([key, value]) => {
        const { id, name, members, last_topup_date, total_topup_amount } = value;

        const { lastPurchaseDate, totalCreditsSpent, nuberOfReports } = members.reduce(
          (acc, member) => {
            const { user_credit_spent, user_last_purchased_date, user_reports_purchased } = member;
            if (user_last_purchased_date) acc.lastPurchaseDate = user_last_purchased_date > acc.lastPurchaseDate ? user_last_purchased_date : acc.lastPurchaseDate;

            acc.totalCreditsSpent += user_credit_spent;
            acc.nuberOfReports += user_reports_purchased;
            return acc;
          },
          {
            lastPurchaseDate: 0,
            totalCreditsSpent: 0,
            nuberOfReports: 0,
          }
        );

        return {
          id,
          organization: name,
          lastPurchaseDate,
          lastCreditTopupDate: last_topup_date,
          totalCreditsPurchased: total_topup_amount,
          totalCreditsSpent,
          nuberOfReports,
        };
      });

      setUserHistoryData(historyData);
    }
  }, [organizationsData]);

  function init() {
    if (currentUser?.uid) {
      getAllOrganization();
      getUserOrganization();
      getStatesData();
      getPurchaseHistory();
      getUserLogs();
      if (userIsAdmin) {
        getTopupData();
        getReportData();
      }
    }
  }

  useEffect(init, []);

  const value = {
    getOrganizationsData: init,
    statesData,
    toggleState,
    toggleJurisdiction,
    userOrganizationData,
    organizationsData,
    userHistoryData,
    userCreditData,
    addMember,
    addOrganization,
    deleteUser,
    updateUser,
    resetUserPassword,
    userCreditBalance,
    purchaseItems,
    addSubtractCredits,
    getUserCreditLogData,
    userCreditsLogData,
    userOcrLogData,
    topupData,
    reportData,
    disabledOrEnableOrganization,
    toggleOhioCrashApp,
    toggleNashvilleMailUser,
    getPurchaseHistory,
    purchaseHistory,
    downloadReport,
    downloadReports,
    getStateLimits,
    setStateLimits,
    getOcrReportData,
    updateOrganizationData,
  };

  return <OrganizationContext.Provider value={value}>{children}</OrganizationContext.Provider>;
}
