import React, { useEffect, useState } from "react";
import * as moment from "moment";
import { Table, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Row } from "reactstrap";
import { createHeaderData, FilterAndSortHeaders } from "../../common";
import { useOrganization } from "../../context/OrganizationContext";
import {
  dateStampToString,
  objectArrayDateSorterAsc,
  objectArrayDateSorterDesc,
  getTodayDateRange,
  getWeeklyDateRange,
  getMonthlyDateRange,
  getYearlyDateRange,
  floatToCurrenctyTxt,
  objectArrayNumberSorterAsc,
  objectArrayNumberSorterDesc,
} from "../../util";
import "./CreditHistoryTable.css";

const {
  headerData: reportsHeaderData,
  headerDataObject: reportsHeaderDataObject,
  initialSortAt: reportsInitialSortAt,
  initialFilterAt: reportsInitialFilterAt,
} = createHeaderData([
  {
    name: "",
    colKey: "id",
    ascSorter: objectArrayNumberSorterAsc,
    descSorter: objectArrayNumberSorterDesc,
    render: false,
  },
  {
    name: "Date of Purchase",
    colKey: "date_of_purchase",
    ascSorter: objectArrayDateSorterAsc,
    descSorter: objectArrayDateSorterDesc,
  },
  {
    name: "Amount",
    colKey: "amount",
  },
  {
    name: "No. of reports",
    colKey: "reports_json",
  },
  {
    name: "Balance Before",
    colKey: "balance_before",
  },
  {
    name: "Balance After",
    colKey: "balance_After",
  },
]);

const {
  headerData: ocrHeaderData,
  headerDataObject: ocrHeaderDataObject,
  initialSortAt: ocrInitialSortAt,
  initialFilterAt: ocrInitialFilterAt,
} = createHeaderData([
  {
    name: "",
    colKey: "id",
    ascSorter: objectArrayNumberSorterAsc,
    descSorter: objectArrayNumberSorterDesc,
    render: false,
  },
  {
    name: "Run Date",
    colKey: "date_of_purchase",
    ascSorter: objectArrayDateSorterAsc,
    descSorter: objectArrayDateSorterDesc,
  },
  {
    name: "Amount",
    colKey: "amount",
  },
  {
    name: "No. of pdf processed",
    colKey: "reports_json",
  },
  {
    name: "Balance Before",
    colKey: "balance_before",
  },
  {
    name: "Balance After",
    colKey: "balance_After",
  },
]);

const CreditHistoryTable = () => {
  const { userCreditsLogData, userOcrLogData } = useOrganization();

  const [allReportsTableData, setAllReportsTableData] = useState([]);
  const [filteredReportsTableData, setFilteredReportsTableData] = useState([]);
  const [dateRangeReportsFilter, setDateRangeReportsFilter] = useState("Last Month");
  const [dateRangeReportsDates, setDateRangeReportsDates] = useState(getMonthlyDateRange());

  const [allOcrTableData, setAllOcrTableData] = useState([]);
  const [filteredOcrTableData, setFilteredOcrTableData] = useState([]);
  const [dateRangeOcrFilter, setDateRangeOcrFilter] = useState("Last Month");
  const [dateRangeOcrDates, setDateRangeOcrDates] = useState(getMonthlyDateRange());

  const [dispalyOcrHistory, setDisplayOcrHistory] = useState(false);

  const dateRangeLookup = {
    Today: getTodayDateRange,
    "Last Week": getWeeklyDateRange,
    "Last Month": getMonthlyDateRange,
    "Current Year": getYearlyDateRange,
  };

  useEffect(() => {
    setDateRangeReportsDates(dateRangeLookup[dateRangeReportsFilter]());
  }, [dateRangeReportsFilter]);

  useEffect(() => {
    setDateRangeOcrDates(dateRangeLookup[dateRangeOcrFilter]());
  }, [dateRangeOcrFilter]);

  useEffect(() => {
    if (dateRangeReportsDates && allReportsTableData.length) {
      const { startDate, endDate } = dateRangeReportsDates;

      const newFilteredData = allReportsTableData.filter(({ date_of_purchase }) => {
        return moment(date_of_purchase).isBetween(startDate, undefined, undefined, "[]");
      });
      setFilteredReportsTableData(newFilteredData);
    }
  }, [dateRangeReportsDates.startDate, allReportsTableData]);

  useEffect(() => {
    if (dateRangeOcrDates && allOcrTableData.length) {
      const { startDate, endDate } = dateRangeOcrDates;

      const newFilteredData = allOcrTableData.filter(({ date_of_purchase }) => {
        return moment(date_of_purchase).isBetween(startDate, undefined, undefined, "[]");
      });

      setFilteredOcrTableData(newFilteredData);
    }
  }, [dateRangeOcrDates.startDate, allOcrTableData]);

  useEffect(() => {
    if (userCreditsLogData) {
      setAllReportsTableData(userCreditsLogData);
    }
  }, [userCreditsLogData]);

  useEffect(() => {
    if (userOcrLogData) {
      setAllOcrTableData(userOcrLogData);
    }
  }, [userOcrLogData]);

  const reportExpenditureHistory = (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Reports Download History</h4>
        <div className="flex-shrink-0">
          <UncontrolledDropdown className="card-header-dropdown">
            <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
              <span className="text-muted">
                {dateRangeReportsFilter}
                <i className="mdi mdi-chevron-down ms-1"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem onClick={() => setDateRangeReportsFilter("Today")}>Today</DropdownItem>
              <DropdownItem onClick={() => setDateRangeReportsFilter("Last Week")}>Last Week</DropdownItem>
              <DropdownItem onClick={() => setDateRangeReportsFilter("Last Month")}>Last Month</DropdownItem>
              <DropdownItem onClick={() => setDateRangeReportsFilter("Current Year")}>Current Year</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </CardHeader>

      <CardBody>
        <div id="credit-history-table-container" className="table-responsive table-card">
          <Table className="table table-borderless table-hover table-nowrap align-middle mb-0">
            <thead className="table-light">
              <tr className="text-muted">
                {allReportsTableData && (
                  <FilterAndSortHeaders
                    {...{
                      allTableData: allReportsTableData,
                      filteredTableData: filteredReportsTableData,
                      setFilteredTableData: setFilteredReportsTableData,
                      headerData: reportsHeaderData,
                      headerDataObject: reportsHeaderDataObject,
                      initialSortAt: reportsInitialSortAt,
                      initialFilterAt: reportsInitialFilterAt,
                    }}
                  />
                )}
              </tr>
            </thead>

            <tbody>
              {(filteredReportsTableData || []).map((item, index) => (
                <tr key={index}>
                  <td>{dateStampToString(item.date_of_purchase)}</td>
                  <td>{floatToCurrenctyTxt(item.amount)}</td>
                  <td>{JSON.parse(item.reports_json).length}</td>
                  <td>{floatToCurrenctyTxt(item.balance_before)}</td>
                  <td>{floatToCurrenctyTxt(item.balance_after)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );

  const ocrRunHistory = (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">OCR History</h4>
        <div className="flex-shrink-0">
          <UncontrolledDropdown className="card-header-dropdown">
            <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
              <span className="text-muted">
                {dateRangeOcrFilter}
                <i className="mdi mdi-chevron-down ms-1"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem onClick={() => setDateRangeOcrFilter("Today")}>Today</DropdownItem>
              <DropdownItem onClick={() => setDateRangeOcrFilter("Last Week")}>Last Week</DropdownItem>
              <DropdownItem onClick={() => setDateRangeOcrFilter("Last Month")}>Last Month</DropdownItem>
              <DropdownItem onClick={() => setDateRangeOcrFilter("Current Year")}>Current Year</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </CardHeader>

      <CardBody>
        <div id="credit-history-table-container" className="table-responsive table-card">
          <Table className="table table-borderless table-hover table-nowrap align-middle mb-0">
            <thead className="table-light">
              <tr className="text-muted">
                {allOcrTableData && (
                  <FilterAndSortHeaders
                    {...{
                      allTableData: allOcrTableData,
                      filteredTableData: filteredOcrTableData,
                      setFilteredTableData: setFilteredOcrTableData,
                      headerData: ocrHeaderData,
                      headerDataObject: ocrHeaderDataObject,
                      initialSortAt: ocrInitialSortAt,
                      initialFilterAt: ocrInitialFilterAt,
                    }}
                  />
                )}
              </tr>
            </thead>

            <tbody>
              {(filteredOcrTableData || []).map((item, index) => (
                <tr key={index}>
                  <td>{dateStampToString(item.date_of_purchase)}</td>
                  <td>{floatToCurrenctyTxt(item.amount)}</td>
                  <td>{JSON.parse(item.reports_json).length}</td>
                  <td>{floatToCurrenctyTxt(item.balance_before)}</td>
                  <td>{floatToCurrenctyTxt(item.balance_after)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );

  const tabStyle = {
    backgroundColor: "#fff",
    padding: "0.92rem 0.92rem",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: "bold",
  };

  const activeTabStyle = {
    ...tabStyle,
    borderBottom: "2px solid #007bff",
  };

  const inactiveTabStyle = {
    ...tabStyle,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  };

  return (
    <React.Fragment>
      <Col xxl={6}>
        <Row style={{ border: "1px solid #e9ebec" }}>
          <Col style={dispalyOcrHistory ? inactiveTabStyle : activeTabStyle} onClick={() => setDisplayOcrHistory(false)}>
            Reports History{" "}
          </Col>
          <Col onClick={() => setDisplayOcrHistory(true)} style={dispalyOcrHistory ? activeTabStyle : inactiveTabStyle}>
            OCR History
          </Col>
        </Row>
        <Row>{dispalyOcrHistory ? ocrRunHistory : reportExpenditureHistory}</Row>
      </Col>
    </React.Fragment>
  );
};

export default CreditHistoryTable;
