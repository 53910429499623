import React, { useState } from "react";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import MetaTags from "react-meta-tags";
import UserHistoryTab from "./UserHistoryTab";
import ManageOrganizations from "./ManageOrganizations";
import StateAndJurisdictionsTab from "./StateAndJurisdictionsTab";
import UsersCreditsTab from "./UsersCreditsTab";
import OCRPermissionsTab from "./OCRPermissionsTab";
import profileBg from "../../assets/images/admin-bg.jpg";
import { useOrganization } from "../../context/OrganizationContext";
import { floatToCurrenctyTxt } from "../../util";
import "./AdminOverview.css";

export default function Index() {
  const { organizationsData, reportData } = useOrganization();
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const excludeOrganizationsIds = [1, 4];

  const { totalSpent, totalReports } = (reportData || []).reduce(
    (acc, curr) => {
      if (excludeOrganizationsIds.includes(curr.organization_id)) return acc;
      const report_json = JSON.parse(curr.reports_json);
      acc.totalSpent += curr.amount;
      acc.totalReports += report_json.length;
      return acc;
    },
    {
      totalSpent: 0,
      totalReports: 0,
    },
  );

  const orgCount = organizationsData
    ? Object.keys(organizationsData).filter((key) => {
        return !excludeOrganizationsIds.includes(parseInt(key));
      }).length
    : "-";

  return (
    <div className="page-content">
      <MetaTags>
        <title>Admin Overview</title>
      </MetaTags>
      <Container fluid>
        <div className="profile-foreground position-relative mx-n4 mt-n4">
          <div className="profile-wid-bg">
            {/* profile background image */}
            <img src={profileBg} alt="" className="profile-wid-img" />
          </div>
        </div>
        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
          <Row className="g-4">
            <div className="col-auto">
              <div className="avatar-lg">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                  alt="user-img"
                  className="img-thumbnail rounded-circle"
                />
              </div>
            </div>

            <Col style={{ display: "grid", alignItems: "center" }}>
              <h3 className="text-white">Administrator</h3>
            </Col>

            <Col xs={12} className="col-lg-auto order-last order-lg-0">
              <Row
                className="text text-white-50 text-center"
                style={{ whiteSpace: "nowrap" }}
              >
                <Col>
                  <div className="p-2">
                    <h4 className="text-white mb-1">{orgCount}</h4>
                    <p className="fs-14 mb-0">Organizations</p>
                  </div>
                </Col>
                <Col>
                  <div className="p-2">
                    <h4 className="text-white mb-1">
                      {floatToCurrenctyTxt(totalSpent)}
                    </h4>
                    <p className="fs-14 mb-0">Total Credit Spent</p>
                  </div>
                </Col>
                <Col>
                  <div className="p-2">
                    <h4 className="text-white mb-1">{totalReports}</h4>
                    <p className="fs-14 mb-0">No. of Reports Purchased</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Row>
          <Col lg={12}>
            <div>
              <div className="d-flex">
                <Nav
                  pills
                  className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      href="#manage-organizations"
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => toggleTab("1")}
                    >
                      <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                      <span className="d-none d-md-inline-block">
                        Manage Organizations
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#user-history"
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => toggleTab("2")}
                    >
                      <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
                      <span className="d-none d-md-inline-block">
                        User History
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#user-credits"
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => toggleTab("3")}
                    >
                      <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
                      <span className="d-none d-md-inline-block">
                        User Credits
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#states-and-jurisdictions"
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => toggleTab("4")}
                    >
                      <i className="ri-price-tag-line d-inline-block d-md-none"></i>{" "}
                      <span className="d-none d-md-inline-block">
                        States and Jurisdictions
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#ocr-permissions"
                      className={classnames({ active: activeTab === "5" })}
                      onClick={() => toggleTab("5")}
                    >
                      <i className="ri-price-tag-line d-inline-block d-md-none"></i>{" "}
                      <span className="d-none d-md-inline-block">
                        OCR Permissions
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <TabContent
                id="admin-overview-tab-content"
                activeTab={activeTab}
                className="pt-4"
              >
                <TabPane tabId="1">
                  <ManageOrganizations />
                </TabPane>
                <TabPane tabId="2">
                  <UserHistoryTab />
                </TabPane>
                <TabPane tabId="3">
                  <UsersCreditsTab />
                </TabPane>
                <TabPane tabId="4">
                  <StateAndJurisdictionsTab />
                </TabPane>
                <TabPane tabId="5">
                  <OCRPermissionsTab />
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
