import React, { useContext, useState, useEffect } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  //   createUserWithEmailAndPassword,
  //   sendPasswordResetEmail,
  //   updateEmail,
  //   updateProfile,
} from "firebase/auth";
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import * as api from "../api";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [userOrg, setUserOrg] = useState();
  const [userNamespace, setUserNamespace] = useState(null);

  const [loading, setLoading] = useState(true);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    localStorage.clear();
    return signOut(auth);
  }

  //   function updateUserEmail(email) {
  //     return updateEmail(auth.currentUser, email)
  //   }

  //   function updateDisplayName(displayName) {
  //     return updateProfile(auth.currentUser, {
  //       displayName,
  //     })
  //   }

  async function updateUserPassword(current_password, new_password) {
    var credential = EmailAuthProvider.credential(auth.currentUser.email, current_password);

    try {
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, new_password);
      return true;
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  async function reloadUser() {
    await currentUser.reload();
    await auth.updateCurrentUser(auth.currentUser);
    await updateUser(auth.currentUser);
  }

  async function updateUser(user) {
    setCurrentUser(user);
    if (user) {
      const idToken = await user.getIdTokenResult();
      setUserOrg(idToken.claims?.organization_id);
      setUserIsAdmin(idToken.claims?.admin);
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      await updateUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    // if (currentUser && !userNamespace) {
    api.getNamespace().then((res) => {
      const [namespace, success] = res;
      if (success) {
        setUserNamespace(namespace);
      }
    });
    // }
  }, [currentUser]);

  async function getUserToken() {
    const idToken = await auth.currentUser?.getIdTokenResult();
    return idToken?.token;
  }

  function setFirebaseDoc(collectionName, docName, updateObj) {
    return setDoc(doc(db, collectionName, docName), updateObj, { merge: true });
  }

  function updateFirebaseDoc(collectionName, docName, updateObj) {
    return updateDoc(doc(db, collectionName, docName), updateObj);
  }

  function getFirebaseDoc(collectionName, docName) {
    return getDoc(doc(db, collectionName, docName));
  }

  const value = {
    // states
    currentUser,
    userIsAdmin,
    userOrg,
    // functions
    login,
    logout,
    reloadUser,
    updateUserPassword,
    getUserToken,
    getFirebaseDoc,
    setFirebaseDoc,
    updateFirebaseDoc,
    userNamespace,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
