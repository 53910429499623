import React, { useState, useEffect } from "react";
import * as moment from "moment";
import { Card, CardBody, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal, ModalBody } from "reactstrap";
import {
  objectArrayStringSorterAsc,
  objectArrayStringSorterDesc,
  objectArrayDateSorterAsc,
  objectArrayDateSorterDesc,
  objectArrayNumberSorterAsc,
  objectArrayNumberSorterDesc,
  stringOptionsSorter,
  dateStampToString,
  getTodayDateRange,
  getYesterdayDateRange,
  getWeeklyDateRange,
  getMonthlyDateRange,
  getYearlyDateRange,
  getQuarterlyDateRange,
  getLast15DaysRange,
  floatToCurrenctyTxt,
} from "../../util";
import { FilterAndSortHeaders, createHeaderData } from "../../common";
import { useOrganization } from "../../context/OrganizationContext";
import { getOrganizationalStats } from "../../api";
import { useAuth } from "../../context/AuthContext";

const { headerData, headerDataObject, initialSortAt, initialFilterAt } = createHeaderData([
  {
    name: "Organizations",
    colKey: "organization",
    ascSorter: objectArrayStringSorterAsc,
    descSorter: objectArrayStringSorterDesc,
    optionsSorter: stringOptionsSorter,
  },
  {
    name: "Last Purchase Date",
    colKey: "lastPurchaseDate",
    ascSorter: objectArrayDateSorterAsc,
    descSorter: objectArrayDateSorterDesc,
  },
  {
    name: "Last Credit Topup Date",
    colKey: "lastCreditTopupDate",
    ascSorter: objectArrayDateSorterAsc,
    descSorter: objectArrayDateSorterDesc,
  },
  {
    name: "Total Credits Purchased",
    colKey: "totalCreditsPurchased",
    ascSorter: objectArrayNumberSorterAsc,
    descSorter: objectArrayNumberSorterDesc,
  },
  {
    name: "Total Credits Spent",
    colKey: "totalCreditsSpent",
    ascSorter: objectArrayNumberSorterAsc,
    descSorter: objectArrayNumberSorterDesc,
  },
  {
    name: "Number of Reports",
    colKey: "nuberOfReports",
    ascSorter: objectArrayNumberSorterAsc,
    descSorter: objectArrayNumberSorterDesc,
  },
]);

const dateRangeLookup = {
  Today: getTodayDateRange,
  Yesterday: getYesterdayDateRange,
  Weekly: getWeeklyDateRange,
  Monthly: getMonthlyDateRange,
  Yearly: getYearlyDateRange,
  Quarterly: getQuarterlyDateRange,
  "Last 15 days": getLast15DaysRange,
};

function OrganizationStatsModal({ orgId, organization, periodTxt, startDate, endDate, isOpen, toggleModal }) {
  const [statsData, setStatsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { getUserToken } = useAuth();

  async function updateData() {
    setLoading(true);
    const [data, status] = await getOrganizationalStats(await getUserToken(), {
      organization_id: orgId,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    });
    setStatsData(data);
    setLoading(false);
  }

  useEffect(() => {
    if (isOpen && orgId !== null) updateData();
  }, [orgId, startDate, endDate, isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered size="xl">
      <div className="modal-content border-0">
        <div className="modal-header p-3 bg-soft-info">
          <h5 className="modal-title" id="myModalLabel">
            {organization}
          </h5>
        </div>
        <ModalBody style={{ maxHeight: "60vh", overflowY: "auto", padding: "1rem" }}>
          <div className="table-responsive table-card">
            <table id="user-history-table" className="table table-borderless table-hover table-nowrap align-middle mb-0">
              <thead className="table-light">
                <tr className="text-muted">
                  <th>State</th>
                  <th>Jurisdiction</th>
                  <th>No. of Reports ({periodTxt})</th>
                  <th>Last Purchase Date</th>
                  <th>Total Purchase Dates</th>
                  <th>Mon</th>
                  <th>Tue</th>
                  <th>Wed</th>
                  <th>Thu</th>
                  <th>Fri</th>
                  <th>Sat</th>
                  <th>Sun</th>
                </tr>
              </thead>
              <tbody>
                {(statsData || []).map(
                  (
                    {
                      state,
                      jurisdiction,
                      last_purchase_date,
                      report_count,
                      unique_purchase_dates,
                      unique_mondays,
                      unique_tuesdays,
                      unique_wednesdays,
                      unique_thursdays,
                      unique_fridays,
                      unique_saturdays,
                      unique_sundays,
                    },
                    index
                  ) => (
                    <tr key={index}>
                      <td>{state}</td>
                      <td>{jurisdiction}</td>
                      <td>{report_count}</td>
                      <td>{dateStampToString(last_purchase_date)}</td>
                      <td>{unique_purchase_dates}</td>
                      <td>{unique_mondays}</td>
                      <td>{unique_tuesdays}</td>
                      <td>{unique_wednesdays}</td>
                      <td>{unique_thursdays}</td>
                      <td>{unique_fridays}</td>
                      <td>{unique_saturdays}</td>
                      <td>{unique_sundays}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default function UserHistoryTab() {
  const { userHistoryData } = useOrganization();

  const [allTableData, setAllTableData] = useState(userHistoryData);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filterDates, setFilterDates] = useState(dateRangeLookup["Monthly"]());
  const [currentDateRangeFilter, setCurrentDateRangeFilter] = useState("Monthly");
  const [loading, setLoading] = useState(true);

  // Stats Modal State
  const [orgId, setOrg_id] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);

  function toggleStatsModal() {
    setIsStatsModalOpen(!isStatsModalOpen);
  }

  function updateData() {
    setLoading(true);
    setFilterDates(dateRangeLookup[currentDateRangeFilter]());
    setLoading(false);
  }

  function onOrganizationClick(orgId, organization) {
    setOrg_id(orgId);
    setOrganization(organization);
    setIsStatsModalOpen(true);
  }

  useEffect(() => updateData(), [currentDateRangeFilter]);

  useEffect(() => {
    const { startDate, endDate } = filterDates;

    const filteredData = userHistoryData.filter((item) => {
      if (item.organization === "FloData") return false;
      if (moment(item.lastCreditTopupDate).isBetween(startDate, undefined, undefined, "[]") || moment(item.lastPurchaseDate).isBetween(startDate, undefined, undefined, "[]")) return true;

      return false;
    });

    setAllTableData(filteredData);
  }, [userHistoryData, filterDates.startDate, filterDates.endDate]);

  return (
    <>
      <OrganizationStatsModal {...{ orgId, organization, isOpen: isStatsModalOpen, toggleModal: toggleStatsModal, periodTxt: currentDateRangeFilter, ...filterDates }} />
      <Card className="top-cards">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">User History</h4>
          <div className="flex-shrink-0">
            <UncontrolledDropdown className="card-header-dropdown">
              <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                <span className="text-muted">
                  {currentDateRangeFilter}
                  <i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => setCurrentDateRangeFilter("Today")}>Today</DropdownItem>
                <DropdownItem onClick={() => setCurrentDateRangeFilter("Yesterday")}>Yesterday</DropdownItem>
                <DropdownItem onClick={() => setCurrentDateRangeFilter("Weekly")}>Weekly</DropdownItem>
                <DropdownItem onClick={() => setCurrentDateRangeFilter("Last 15 days")}>Last 15 days</DropdownItem>
                <DropdownItem onClick={() => setCurrentDateRangeFilter("Monthly")}>Monthly</DropdownItem>
                <DropdownItem onClick={() => setCurrentDateRangeFilter("Quarterly")}>Quarterly</DropdownItem>
                <DropdownItem onClick={() => setCurrentDateRangeFilter("Yearly")}>Yearly</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive table-card">
            {loading && <div className="text-center">Loading {currentDateRangeFilter} history...</div>}
            <table id="user-history-table" className="table table-borderless table-hover table-nowrap align-middle mb-0">
              <thead className="table-light">
                <tr className="text-muted">
                  {allTableData && (
                    <FilterAndSortHeaders
                      {...{
                        allTableData,
                        filteredTableData,
                        setFilteredTableData,
                        headerData,
                        headerDataObject,
                        initialSortAt,
                        initialFilterAt,
                      }}
                    />
                  )}
                </tr>
              </thead>
              <tbody>
                {(filteredTableData || []).map(({ id, organization, lastPurchaseDate, lastCreditTopupDate, totalCreditsPurchased, totalCreditsSpent, nuberOfReports }, index) => (
                  <tr key={index}>
                    <td onClick={() => onOrganizationClick(id, organization)} style={{ cursor: "pointer" }}>
                      {organization}
                    </td>
                    <td>{dateStampToString(lastPurchaseDate)}</td>
                    <td>{dateStampToString(lastCreditTopupDate)}</td>
                    <td>{floatToCurrenctyTxt(totalCreditsPurchased)}</td>
                    <td>{floatToCurrenctyTxt(totalCreditsSpent)}</td>
                    <td>{nuberOfReports}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
