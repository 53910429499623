import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  UncontrolledCollapse,
  Modal,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toggleCollapseClass } from "../../util";
import { useAuth } from "../../context/AuthContext";

export default function ResetPassword() {
  const { updateUserPassword } = useAuth();

  const [successMsg, setSuccessMsg] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      currentPassword: "",
      newPassword: "",
      cnfNewPassword: "",
    },

    validate: (values, props) => {
      const errors = {};
      if (values.newPassword !== values.cnfNewPassword) {
        errors.cnfNewPassword = "Password does not match";
      }
      return errors;
    },

    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .min(6, "Too Short!")
        .max(20, "Too Long!")
        .required("Please Enter Current Password"),
      newPassword: Yup.string()
        .min(6, "Too Short!")
        .max(20, "Too Long!")
        .required("Please Enter New Password"),
      cnfNewPassword: Yup.string()
        .min(6, "Too Short!")
        .max(20, "Too Long!")
        .required("Please Enter Confirm New Password"),
    }),

    onSubmit: async (values) => {
      const { currentPassword, newPassword, cnfNewPassword } = values;
      const success = await updateUserPassword(currentPassword, newPassword);
      if (success) {
        setSuccessMsg("Password updated successfully.");
      } else {
        setSuccessMsg("Failed to upate password. Please try again.");
      }
      toggleSuccessModal();
    },
  });

  const alertClass =
    successMsg === "Password updated successfully."
      ? "alert-success"
      : "alert-danger";

  return (
    <>
      <Modal isOpen={successModalOpen} toggle={toggleSuccessModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0">
            <div
              className={`alert ${alertClass} alert-dismissible fade show m-0`}
              role="alert"
            >
              <div dangerouslySetInnerHTML={{ __html: successMsg }} />
              <button
                type="button"
                className="btn-close"
                onClick={toggleSuccessModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button bg-transparent shadow-none collapsed"
            type="button"
            id="reset-pass"
            onClick={toggleCollapseClass}
          >
            <span className="text-muted text-uppercase fs-13 fw-medium">
              Update Password
            </span>
          </button>
        </h2>
        <UncontrolledCollapse
          id="history-dropdown"
          toggler="#reset-pass"
          defaultOpen={true}
        >
          <Row style={{ background: "transparent" }}>
            <Col lg={12}>
              <div style={{ padding: "1rem 1.25rem" }}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                  action="#"
                >
                  <Col md={12} className="mb-3">
                    <div>
                      <Label htmlFor="currentPassword" className="form-label">
                        Current Password
                      </Label>
                      <div className="form-icon">
                        <Input
                          type="password"
                          className="form-control form-control-icon"
                          id="currentPassword"
                          name="currentPassword"
                          placeholder="Enter Current Password"
                          value={validation.values.currentPassword || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.currentPassword &&
                            validation.errors.currentPassword
                              ? true
                              : false
                          }
                        />
                        <i className="ri-lock-password-line"></i>
                      </div>
                      {validation.touched.currentPassword &&
                        validation.errors.currentPassword && (
                          <div className="fs-14">
                            {validation.errors.currentPassword}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <div>
                      <Label htmlFor="newPassword" className="form-label">
                        New Password
                      </Label>
                      <div className="form-icon">
                        <Input
                          type="password"
                          className="form-control form-control-icon"
                          id="newPassword"
                          name="newPassword"
                          placeholder="Enter New Password"
                          value={validation.values.newPassword || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.newPassword &&
                            validation.errors.newPassword
                              ? true
                              : false
                          }
                        />
                        <i className="ri-lock-password-line"></i>
                      </div>
                    </div>
                    {validation.touched.newPassword &&
                      validation.errors.newPassword && (
                        <div className="fs-14">
                          {validation.errors.newPassword}
                        </div>
                      )}
                  </Col>

                  <Col md={12} className="mb-4">
                    <div>
                      <Label htmlFor="cnfNewPassword" className="form-label">
                        Confirm New Password
                      </Label>
                      <div className="form-icon">
                        <Input
                          type="password"
                          className="form-control form-control-icon"
                          id="cnfNewPassword"
                          name="cnfNewPassword"
                          placeholder="Re-Enter New Password"
                          value={validation.values.cnfNewPassword || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.cnfNewPassword &&
                            validation.errors.cnfNewPassword
                              ? true
                              : false
                          }
                        />
                        <i className="ri-lock-password-line"></i>
                      </div>
                    </div>
                    {validation.touched.cnfNewPassword &&
                      validation.errors.cnfNewPassword && (
                        <div className="fs-14">
                          {validation.errors.cnfNewPassword}
                        </div>
                      )}
                  </Col>

                  <div className="d-flex justify-content-end">
                    <Button type="submit" color="primary" className="w-100">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </UncontrolledCollapse>
      </div>
    </>
  );
}
