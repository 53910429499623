import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import classnames from "classnames";
import { useOrganization } from "../../context/OrganizationContext";

export default function StateAndJurisdictionsTab() {
  const {
    statesData,
    toggleState,
    toggleJurisdiction,
    getStateLimits,
    setStateLimits,
  } = useOrganization();
  const [stateData, setStateData] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [juryDisabled, setJuryDisabled] = useState(true);
  const [viewEnableState, setViewEnableState] = useState(false);
  const [allStateLimits, setAllStateLimits] = useState({});

  const [errorMsg, setErrorMsg] = useState({ msg: "", type: "success" });
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

  async function setStateAndJurisdiction() {
    const sData = statesData.reduce((acc, { state, jurisdiction, active }) => {
      if (!acc[state]) acc[state] = [];
      acc[state].push({ jurisdiction, checked: active });
      return acc;
    }, {});

    const sOptions = Object.entries(sData).map(([state, jurisdictions]) => {
      jurisdictions.sort((a, b) =>
        a.jurisdiction.localeCompare(b.jurisdiction),
      );

      return {
        state,
        checked: jurisdictions.reduce(
          (acc, curr) => acc || curr.checked,
          false,
        ),
      };
    });

    setStateOptions(sOptions);
    setStateData(sData);
  }

  useEffect(() => {
    if (statesData) setStateAndJurisdiction();
  }, [statesData]);

  async function initStateLimits() {
    const limits = await getStateLimits();
    const newStateLimits = {};
    for (let stateLimit of limits) {
      const state = stateLimit.state;
      const limit = stateLimit.download_limit;
      newStateLimits[state] = limit;
    }
    setAllStateLimits(newStateLimits);
  }

  useEffect(() => {
    initStateLimits();
  }, []);

  async function onStateChange(e) {
    return;
    const state = e.target.name;
    const index = stateOptions.findIndex((item) => item.state === state);
    const newStateOptions = [...stateOptions];
    const newActive = !newStateOptions[index].checked;
    newStateOptions[index].checked = newActive;
    const newJuryData = stateData[state].map((jurisdiction) => ({
      ...jurisdiction,
      checked: newActive,
    }));
    const newStateData = { ...stateData, [state]: newJuryData };

    const ok = await toggleState(state, newActive);
    if (ok) {
      setStateOptions(newStateOptions);
      setStateData(newStateData);
    }
  }

  async function onJurisdictionChange(e) {
    const jurisdiction = e.target.name;
    const index = stateData[selectedState].findIndex(
      (item) => item.jurisdiction === jurisdiction,
    );
    const newStateData = { ...stateData };
    const newActive = !newStateData[selectedState][index].checked;
    newStateData[selectedState][index].checked = newActive;

    const newStateOptions = Object.entries(newStateData).map(
      ([state, jurisdictions]) => ({
        state,
        checked: jurisdictions.reduce(
          (acc, curr) => acc || curr.checked,
          false,
        ),
      }),
    );

    const ok = await toggleJurisdiction(
      selectedState,
      newStateData[selectedState][index].jurisdiction,
      newActive,
    );
    if (ok) {
      setStateData(newStateData);
      setStateOptions(newStateOptions);
    }
  }

  function onStateSettingsClick(stateClicked) {
    if (selectedState === stateClicked) {
      setJuryDisabled(true);
      setTimeout(() => {
        setSelectedState("");
      }, 420);
    } else {
      setJuryDisabled(false);
      setSelectedState(stateClicked);
    }
  }

  async function onLimitUpdate(state) {
    const limit = allStateLimits[state] || 0;
    const success = await setStateLimits(state, limit);

    if (success) {
      setErrorMsg({
        msg: `Download Limit set to <strong>${limit}<strong> for <strong>${state}</strong>`,
        type: "success",
      });
    } else {
      setErrorMsg({
        msg: `Failed to update download limit for <strong>${state}</strong>`,
        type: "danger",
      });
    }

    toggleErrorModal();
  }

  function onlyInt(value) {
    if (value === "") {
      return "";
    }
    return parseInt(value);
  }

  const totalReportsToDownload = Object.values(allStateLimits).reduce(
    (acc, curr) => acc + curr,
    0,
  );

  return (
    <>
      <Card className="top-cards">
        <CardBody>
          <Row>
            <Col className={classnames("col-transition")}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">States</h4>
                  <span>
                    No. of reports to be downloaded {totalReportsToDownload}
                  </span>
                </CardHeader>
                <CardBody className="list-card-body">
                  <div className="d-flex justify-content-between">
                    <p className="text-muted">Select States to enable them</p>
                    <div>
                      <Input
                        className="form-check-input me-1"
                        type="checkbox"
                        name="view_enabled_states"
                        checked={viewEnableState}
                        onChange={() => setViewEnableState(!viewEnableState)}
                      />
                      View Enabled States
                    </div>
                  </div>
                  <div>
                    <ListGroup>
                      {stateOptions
                        .filter((item) => !viewEnableState || item.checked)
                        .sort((a, b) => a.state.localeCompare(b.state))
                        .map((item, key) => (
                          <ListGroupItem
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <Input
                                className="form-check-input me-1"
                                type="checkbox"
                                name={item.state}
                                checked={item.checked}
                                onChange={onStateChange}
                              />
                              {item.state}
                            </div>
                            <div className="d-flex gap-4">
                              <div className="d-flex gap-2">
                                <input
                                  className="form-control state-and-jurisdiction-limit-input"
                                  type="number"
                                  id={`${item.state}-limit`}
                                  name={item.state + "-limit"}
                                  value={allStateLimits[item.state] || ""}
                                  onChange={(e) =>
                                    setAllStateLimits({
                                      ...allStateLimits,
                                      [item.state]: onlyInt(e.target.value),
                                    })
                                  }
                                  style={{
                                    textAlign: "center",
                                    maxWidth: "4rem",
                                  }}
                                  placeholder="Limit"
                                />
                                <button
                                  className="btn btn-primary"
                                  onClick={() => onLimitUpdate(item.state)}
                                >
                                  Save
                                </button>
                              </div>
                              <button
                                onClick={() => onStateSettingsClick(item.state)}
                                className="btn btn-icon btn-ghost-secondary rounded-circle fs-16"
                              >
                                <i className="ri ri-settings-3-fill"></i>
                              </button>
                            </div>
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col
              className={classnames("col-transition", {
                disabledCol: juryDisabled,
              })}
            >
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Jurisdictions</h4>
                </CardHeader>
                <CardBody className="list-card-body">
                  <p className="text-muted">
                    {selectedState
                      ? `Select jurisdictions in ${selectedState} to enable them`
                      : "Select a state to see jurisdictions"}
                  </p>
                  <div>
                    <ListGroup>
                      {selectedState &&
                        stateData[selectedState].map((item, key) => (
                          <ListGroupItem key={key} tag="label">
                            <Input
                              className="form-check-input me-1"
                              type="checkbox"
                              checked={item.checked}
                              name={item.jurisdiction}
                              onChange={onJurisdictionChange}
                            />
                            {item.jurisdiction}
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0">
            <div
              className={`alert alert-${errorMsg.type} alert-dismissible fade show m-0`}
              role="alert"
            >
              <div dangerouslySetInnerHTML={{ __html: errorMsg.msg }} />
              <button
                type="button"
                className="btn-close"
                onClick={toggleErrorModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}
