import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useAuth } from "../../context/AuthContext";
import { getInitials } from "../../util";

const ProfileDropdown = () => {
  const { currentUser, logout } = useAuth();

  const history = useHistory();

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  async function onLogout() {
    await logout();
  }

  async function onProfileSettings() {
    history.push("/user-settings");
  }

  return (
    <>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <span
              style={{ color: "white" }}
              className="d-flex justify-content-center align-items-center fs-20 bg-primary rounded-circle avatar-sm"
            >
              <strong>{getInitials(currentUser.displayName)}</strong>
            </span>
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {currentUser.displayName}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Welcome {currentUser.displayName}!
          </h6>
          <DropdownItem href="#" onClick={onProfileSettings}>
            <i className="mdi mdi-account-cog text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-reset-password">
              Profile Settings
            </span>
          </DropdownItem>

          <DropdownItem href="#" onClick={onLogout}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ProfileDropdown;
