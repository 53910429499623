import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Col, Row, Label } from "reactstrap";
import OcrTable from "./OcrTable";
import { useOrganization } from "../../context/OrganizationContext";
import { getOcrPageStateJury, getOcrData } from "../../api";
import { useAuth } from "../../context/AuthContext";
import { getConditionalTitleCase } from "../../util";

export default function OcrSearchSelections() {
  const { getOrganizationsData } = useOrganization();

  const [selectedState, setSelectedState] = useState("");
  const [selectedJurisdiction, setSelectedJurisdiction] = useState("");
  const [selectedWebsite, setSelectedWebsite] = useState("");

  const [stateOptions, setStateOptions] = useState({});
  const [juryOptions, setJuryOptions] = useState([]);

  const [websiteOptions, setWebsiteOptions] = useState([]);

  const [allOcrData, setAllOcrData] = useState({});

  const [loading, setLoading] = useState(true);

  const { getUserToken } = useAuth();

  async function setStateAndJurisdiction() {
    const userToken = await getUserToken();
    const [[data, status], [ocrData, ocrStatus]] = await Promise.all([getOcrPageStateJury(userToken), getOcrData(userToken)]);

    setAllOcrData(ocrData);

    setStateOptions(data.jurisdictions);
    setWebsiteOptions(data.websites);
    setLoading(false);
  }

  useEffect(() => {
    setStateAndJurisdiction();
    getOrganizationsData();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const options = stateOptions[selectedState]?.map((jurisdiction) => jurisdiction);
      setJuryOptions(options);
    }
  }, [selectedState, stateOptions]);

  useEffect(() => {
    if (selectedWebsite) {
      setSelectedState("");
      setSelectedJurisdiction("");
    }
  }, [selectedWebsite]);

  useEffect(() => {
    if (selectedState) {
      setSelectedWebsite("");
    }
  }, [selectedState]);

  const stateLabel = selectedState ? { value: selectedState, label: selectedState } : null;
  const juryLabel = selectedJurisdiction
    ? {
        value: selectedJurisdiction,
        label: getConditionalTitleCase(selectedJurisdiction),
      }
    : null;
  const websiteLabel = selectedWebsite
    ? {
        value: selectedWebsite,
        label: getConditionalTitleCase(selectedWebsite),
      }
    : null;

  function resetSelections() {
    setSelectedState("");
    setSelectedJurisdiction("");
    setSelectedWebsite("");
  }

  return (
    <>
      <Row>
        <Col>
          <Label>State</Label>
          <Select
            isDisabled={loading}
            className={loading ? "show-loader" : ""}
            placeholder={loading ? "" : "Select State..."}
            name="state"
            value={stateLabel}
            options={Object.keys(stateOptions)
              .sort((a, b) => a.localeCompare(b))
              .map((state) => ({ value: state, label: state }))}
            onChange={({ value }) => {
              setSelectedState(value);
              setSelectedJurisdiction("");
            }}
          />
        </Col>
        <Col>
          <Label>Jurisdiction</Label>
          <Select
            className={loading ? "show-loader" : ""}
            isDisabled={loading || !selectedState}
            placeholder={loading ? "" : "Select Jurisdictions..."}
            name="jurisdiction"
            value={juryLabel}
            getOptionLabel={(option) => `${getConditionalTitleCase(option.label)} ($${allOcrData[option.value]?.price})`}
            options={juryOptions
              .sort((a, b) => a.localeCompare(b))
              .map((jury) => ({
                value: jury,
                label: jury,
              }))}
            onChange={({ value }) => {
              setSelectedJurisdiction(value);
            }}
          />
        </Col>
        <Col
          style={{
            maxWidth: 50,
            padding: 0,
            display: "grid",
            placeItems: "center",
          }}
        >
          <div style={{ marginBottom: -28 }}>OR</div>
        </Col>
        <Col>
          <Label>Website</Label>
          <Select
            className={loading ? "show-loader" : ""}
            isDisabled={loading}
            placeholder={loading ? "" : "Select Website..."}
            name="website"
            value={websiteLabel}
            getOptionLabel={(option) => `${getConditionalTitleCase(option.label)} ($${allOcrData[option.value]?.price})`}
            options={websiteOptions
              .sort((a, b) => a.localeCompare(b))
              .map((site) => ({
                value: site,
                label: `${getConditionalTitleCase(site)} ($${allOcrData[site]?.price})`,
              }))}
            onChange={({ value }) => {
              setSelectedWebsite(value);
            }}
          />
        </Col>
      </Row>
      <OcrTable
        {...{
          selectedState,
          selectedJurisdiction,
          selectedWebsite,
          resetSelections,
          ocrPrice: allOcrData[selectedJurisdiction]?.price,
          ocrLimit: allOcrData[selectedJurisdiction]?.file_upload_limit,
        }}
      />
    </>
  );
}
