import React, { useState, useEffect } from "react";
import { Col, Row, Table, UncontrolledCollapse } from "reactstrap";
import { useSelector } from "react-redux";
import { toggleCollapseClass, objectArrayDateSorterAsc } from "../../util";
import { ColumnFilter } from "../../common";
import { LoadingAllDownloadBtn, LoadingDownloadBtn } from "./LoadingDownloadBtn";
import { useOrganization } from "../../context/OrganizationContext";
import * as moment from "moment";

export default function TodayReports() {
  const instantPurchasedItems = useSelector((state) => {
    return state.ReportSearch.instantPurchasedItems;
  });
  const { purchaseHistory } = useOrganization();

  const initialFilterAt = {
    jurisdiction: [],
    "Report Number": [],
    "Crash Date": [],
    Size: [],
    Action: [],
  };

  const initialSortAt = {
    jurisdiction: 2,
    "Report Number": 2,
    "Crash Date": 2,
    Size: 2,
    Action: 2,
  };

  const [allTableData, setAllTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filterAt, setFilterAt] = useState(initialFilterAt);
  const [sortAt, setSortAt] = useState(initialSortAt);
  const [filterOrderKey, setFilterOrderKey] = useState([]);

  useEffect(() => {
    let data = purchaseHistory
      ?.filter((item) => {
        const diff = moment().diff(item.date_of_purchase, "days");
        return diff <= 0;
      })
      .map((item) => {
        return {
          id: item.id,
          state: item.state,
          jurisdiction: item.jurisdiction,
          "Crash Date": moment(item.crash_date).format("MM/DD/YYYY"),
          "Report Number": item.report_number,
          Size: (item.file_size / 1024).toFixed(2) + "MB",
        };
      });

    data = data.concat(
      instantPurchasedItems?.map((item) => {
        return {
          id: item.id,
          state: item.state,
          jurisdiction: item.jurisdiction,
          "Report Number": "---",
          "Crash Date": item["Crash Date"],
          Size: (item.Size / 1024).toFixed(2) + "MB",
        };
      })
    );

    data = data.reduce((acc, curr) => {
      if (!acc[curr.id]) acc[curr.id] = curr;
      return acc;
    }, {});

    data = Object.values(data);

    if (data.length) {
      setAllTableData(data);
      setFilteredTableData(data);
    }
  }, [instantPurchasedItems, purchaseHistory]);

  function updateSortAt(colKey) {
    setSortAt({ ...initialSortAt, [colKey]: (sortAt[colKey] + 1) % 3 });
  }

  function getSortingKey() {
    for (let [key, value] of Object.entries(sortAt)) {
      if (value === 0) {
        return [key, "asc"];
      } else if (value === 1) {
        return [key, "desc"];
      }
    }

    return ["", ""];
  }

  function sortData(data) {
    const [sortKey, order] = getSortingKey();

    if (order === "asc") {
      if (["Crash Date"].includes(sortKey)) {
        data.sort((a, b) => new Date(a[sortKey]) - new Date(b[sortKey]));
      } else {
        data.sort((a, b) => a[sortKey].toString().localeCompare(b[sortKey].toString()));
      }
    } else if (order === "desc") {
      if (["Crash Date"].includes(sortKey)) {
        data.sort((b, a) => new Date(a[sortKey]) - new Date(b[sortKey]));
      } else {
        data.sort((b, a) => a[sortKey].toString().localeCompare(b[sortKey].toString()));
      }
    }
    return data;
  }

  function filterData(data) {
    for (let [key, value] of Object.entries(filterAt)) {
      const values = value.map((e) => e.value);
      if (values.length === 0) {
        if (filterOrderKey.includes(key)) setFilterOrderKey(filterOrderKey.filter((e) => e !== key));
        continue;
      }

      if (!filterOrderKey.includes(key)) setFilterOrderKey([...filterOrderKey, key]);

      data = data.filter((e) => values.includes(e[key]));
    }

    return data;
  }

  useEffect(() => {
    setFilteredTableData(sortData(filterData([...allTableData])));
  }, [sortAt, filterAt, allTableData]);

  function getSortClass(colKey) {
    switch (sortAt[colKey]) {
      case 0:
        return "bx-sort-a-z";
      case 1:
        return "bx-sort-z-a";
      default:
        return "bx-sort-z-a";
    }
  }

  function getFilterOptions(colKey) {
    let options = [...allTableData];

    const fIndex = filterOrderKey.findIndex((e) => e === colKey);
    if (fIndex !== -1) {
      for (let i = 1; i <= fIndex; i++) {
        const preKey = filterOrderKey[i - 1];
        const preOptions = filterAt[preKey].map((e) => e.value);

        options = options.filter((e) => preOptions.includes(e[preKey]));
      }
    } else {
      options = [...filteredTableData];
    }

    const toptions = options.reduce((acc, curr) => {
      acc.add(curr[colKey]);
      return acc;
    }, new Set());

    return [...toptions.values()].map((e) => ({ value: e, label: e }));
  }

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button className="accordion-button bg-transparent shadow-none" type="button" id="today-cart-items" onClick={toggleCollapseClass}>
          <span className="text-muted text-uppercase fs-13 fw-medium">Today</span> <span className="badge bg-success rounded-pill align-middle ms-1">{allTableData.length}</span>
        </button>
      </h2>

      {allTableData.length ? (
        <UncontrolledCollapse id="today-dropdown" toggler="#today-cart-items" defaultOpen={true}>
          <Row>
            <Col
              lg={12}
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "8px 1.7rem 8px 0px",
              }}
            >
              <LoadingAllDownloadBtn reports={filteredTableData} />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="table-responsive downloaded-tables-container">
                <Table className="table-borderless align-middle mb-0 downloaded-tables">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">
                        <div>
                          <span>Jurisdiction</span>
                          <span className="filters-and-sort-icons">
                            <i onClick={() => updateSortAt("jurisdiction")} className={`bx ${getSortClass("jurisdiction")}`}></i>
                            <ColumnFilter total={5} index={0} colKey="jurisdiction" options={getFilterOptions("jurisdiction")} setFilterAt={setFilterAt} filterAt={filterAt} />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div>
                          <span>Report Number</span>
                          <span className="filters-and-sort-icons">
                            <i onClick={() => updateSortAt("Report Number")} className={`bx ${getSortClass("Report Number")}`}></i>
                            <ColumnFilter total={5} index={1} colKey="Report Number" options={getFilterOptions("Report Number")} setFilterAt={setFilterAt} filterAt={filterAt} />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div>
                          <span>Crash Date</span>
                          <span className="filters-and-sort-icons">
                            <i onClick={() => updateSortAt("Crash Date")} className={`bx ${getSortClass("Crash Date")}`}></i>
                            <ColumnFilter
                              total={5}
                              index={2}
                              colKey="Crash Date"
                              options={getFilterOptions("Crash Date")}
                              setFilterAt={setFilterAt}
                              filterAt={filterAt}
                              optionsSorter={objectArrayDateSorterAsc("Crash Date")}
                            />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div>
                          <span>Size</span>
                          <span className="filters-and-sort-icons">
                            <i onClick={() => updateSortAt("Size")} className={`bx ${getSortClass("Size")}`}></i>
                            <ColumnFilter total={5} index={3} colKey="Size" options={getFilterOptions("Size")} setFilterAt={setFilterAt} filterAt={filterAt} />
                          </span>
                        </div>
                      </th>

                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTableData.map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                fontWeight: "bold",
                                display: "grid",
                                placeItems: "center",
                                fontSize: "1.1rem",
                                color: "grey",
                              }}
                              className="me-3 rounded-circle avatar-sm p-2 bg-light"
                            >
                              {item.state}
                            </div>
                            <div className="ms-3 flex-grow-1">
                              <h6 className="fs-14 mb-0">{item.jurisdiction}</h6>
                            </div>
                          </div>
                        </td>
                        <td>{item["Report Number"]}</td>
                        <td>{item["Crash Date"]}</td>
                        <td>{item["Size"]}</td>
                        <td>
                          <LoadingDownloadBtn reportId={item.id} stateAbb={item.state} crashDate={item["Crash Date"]} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </UncontrolledCollapse>
      ) : null}
    </div>
  );
}
