import * as ActionType from "./actionType";

// get reports
export const getReportData = (sendData) => ({
  type: ActionType.GET_REPORT_DATA,
  payload: sendData,
});

export const getReportDataSuccess = (reportItems) => ({
  type: ActionType.GET_REPORT_DATA_SUCCESS,
  payload: reportItems,
});

export const getReportDataFail = (error) => ({
  type: ActionType.GET_REPORT_DATA_FAIL,
  payload: error,
});

// get cart data
export const getCartData = () => ({
  type: ActionType.GET_CART_DATA,
});

export const getCartDataSuccess = (cartItems) => ({
  type: ActionType.GET_CART_DATA_SUCCESS,
  payload: cartItems,
});

export const getCartDataFail = (error) => ({
  type: ActionType.GET_CART_DATA_FAIL,
  payload: error,
});

// add cart data
export const addCartData = (cartItem) => ({
  type: ActionType.ADD_CART_DATA,
  payload: cartItem,
});

export const addCartDataSuccess = (cartItem) => ({
  type: ActionType.ADD_CART_DATA_SUCCESS,
  payload: cartItem,
});

export const addCartDataFail = (error) => ({
  type: ActionType.ADD_CART_DATA_FAIL,
  payload: error,
});

// remove cart data
export const removeCartData = (cartItem) => ({
  type: ActionType.REMOVE_CART_DATA,
  payload: cartItem,
});

export const removeCartDataSuccess = (cartItem) => ({
  type: ActionType.REMOVE_CART_DATA_SUCCESS,
  payload: cartItem,
});

export const removeCartDataFail = (error) => ({
  type: ActionType.REMOVE_CART_DATA_FAIL,
  payload: error,
});

// instant purchase
export const instantPurchase = (cartItems) => ({
  type: ActionType.INSTANT_PURCHASE_CART_DATA,
  payload: cartItems,
});

export const instantPurchaseSuccess = (cartItems) => ({
  type: ActionType.INSTANT_PURCHASE_CART_DATA_SUCCESS,
  payload: cartItems,
});

export const instantPurchaseFail = (error) => ({
  type: ActionType.INSTANT_PURCHASE_CART_DATA_FAIL,
  payload: error,
});
