import React, { useEffect, useState } from "react";
import { Container, Modal, ModalBody } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import OcrSearchSelections from "./OcrSearchSelections";
// import { checkoutSession } from '../../api'
// import { useAuth } from '../../context/AuthContext'
// import { useHistory } from 'react-router-dom'
// import { useOrganization } from '../../context/OrganizationContext'

export default function Index() {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>OCR Reports</title>
          </MetaTags>
          <BreadCrumb title="OCR Reports" pageTitle="OCR Reports" />
          <OcrSearchSelections />
        </Container>
      </div>
    </>
  );
}
