import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";

export default function Index() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Payment History</title>
          </MetaTags>
          <BreadCrumb title="Payment History" pageTitle="Payments" />
        </Container>
      </div>
    </React.Fragment>
  );
}
