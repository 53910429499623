import * as moment from "moment";

const toDate = (e) => new Date(e);
const toNumber = (e) => (typeof e === "number" ? e : Number(e?.trim()));
const toString = (e) => (typeof e === "string" ? e.trim() : e.toString());

// date sorters
export const objectArrayDateSorterAsc = (colKey) => (a, b) => toDate(a[colKey]) - toDate(b[colKey]);
export const objectArrayDateSorterDesc = (colKey) => (b, a) => toDate(a[colKey]) - toDate(b[colKey]);

// number sorters
export const objectArrayNumberSorterAsc = (colKey) => (a, b) => toNumber(a[colKey]) - toNumber(b[colKey]);
export const objectArrayNumberSorterDesc = (colKey) => (b, a) => toNumber(a[colKey]) - toNumber(b[colKey]);

// string sorters
export const objectArrayStringSorterAsc = (colKey) => (a, b) => toString(a[colKey]).localeCompare(toString(b[colKey]));
export const objectArrayStringSorterDesc = (colKey) => (b, a) => toString(a[colKey]).localeCompare(toString(b[colKey]));

// filter options sorters
export const dateOptionsSorter = (a, b) => toDate(a.value) - toDate(b.value);
export const numberOptionsSorter = (a, b) => toNumber(a.value) - toNumber(b.value);
export const stringOptionsSorter = (a, b) => toString(a.value).localeCompare(toString(b.value));

export const getInitials = (name) =>
  name
    .split(" ")
    .map((n) => n[0].toUpperCase())
    .join("");

export const padLeft = (obj) => obj.toString().padStart(2, "0");

export const randomInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const dateStampToString = (dateStamp) => {
  if (!dateStamp) return "---";
  return new Date(dateStamp).toLocaleDateString("en-US");
};

export const floatToCurrenctyTxt = (number) => {
  if (!number) return "$0";
  number = parseFloat(number);

  if (number >= 1000) {
    return `$${(number / 1000).toFixed(2)}k`;
  }

  return `$${number.toFixed(2)}`;
};

export const genRand = (len) =>
  Math.random()
    .toString(36)
    .substring(2, len + 2);

export const randomDate = (startYear, endYear) => `${padLeft(randomInteger(1, 12))}/${padLeft(randomInteger(1, 31))}/${randomInteger(startYear, endYear)}`;

export const toggleCollapseClass = (e) => e.target.classList.toggle("collapsed");

export const dateFormat = "MM/DD/YYYY";

export const today = () => moment().format(dateFormat);

export const getTodayDateRange = () => {
  const startDate = today();
  return { startDate, endDate: startDate };
};

export const getYesterdayDateRange = () => {
  const startDate = moment().subtract(1, "days").format(dateFormat);
  return { startDate, endDate: startDate };
};

export const getWeeklyDateRange = () => {
  const startDate = moment().startOf("isoWeek").format(dateFormat);
  return { startDate: startDate, endDate: today() };
};

export const getMonthlyDateRange = () => {
  const startDate = moment().startOf("month").format(dateFormat);
  return { startDate: startDate, endDate: today() };
};

export const getYearlyDateRange = () => {
  const startDate = moment().startOf("year").format(dateFormat);
  return { startDate: startDate, endDate: today() };
};

export const getQuarterlyDateRange = () => {
  const startDate = moment().quarter(moment().quarter()).startOf("quarter").format(dateFormat);
  return { startDate: startDate, endDate: today() };
};

export const getLast15DaysRange = () => {
  const startDate = moment().subtract(15, "days").format(dateFormat);
  return { startDate, endDate: today() };
};

const prepositions = [
  "about",
  "like",
  "above",
  "near",
  "across",
  "of",
  "after",
  "off",
  "against",
  "on",
  "along",
  "onto",
  "among",
  "opposite",
  "around",
  "out",
  "as",
  "outside",
  "at",
  "over",
  "before",
  "past",
  "behind",
  "round",
  "below",
  "since",
  "beneath",
  "than",
  "beside",
  "through",
  "between",
  "to",
  "beyond",
  "towards",
  "by",
  "under",
  "despite",
  "underneath",
  "down",
  "unlike",
  "during",
  "until",
  "except",
  "up",
  "for",
  "upon",
  "from",
  "via",
  "in",
  "with",
  "inside",
  "within",
  "into",
  "without",
];

export const getConditionalTitleCase = (text) => {
  const words = text.split("_");
  // const titleCasedWords = words.map((word) => {
  //   if (word.toLowerCase() === word) return word[0].toUpperCase() + word.slice(1);
  //   return word;
  // });
  const titleCasedWords = words.map((word) => (prepositions.includes(word) ? word : word[0].toUpperCase() + word.slice(1)));
  return titleCasedWords.join(" ");
};

export const toHHMMSS = (sec_num) => {
  // var sec_num = parseInt(this, 10)
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  seconds = parseFloat(seconds).toFixed(2) + "s";

  // if (seconds !== "00.00") {
  //   seconds = seconds + "s";
  // }

  return hours + ":" + minutes + ":" + seconds;
};
