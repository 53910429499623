import React, { useEffect, useState } from "react";
import { Container, Modal, ModalBody } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import ReportSearchSelections from "./ReportSearchSelections";
import { checkoutSession } from "../../api";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { useOrganization } from "../../context/OrganizationContext";

export default function Index() {
  const history = useHistory();
  const { getUserToken } = useAuth();

  const { getUserCreditLogData, getOrganizationsData } = useOrganization();

  const [msg, setMsg] = useState({});
  const [msgModalOpen, setMsgModalOpen] = useState(false);
  const toggleMsgModal = () => setMsgModalOpen(!msgModalOpen);

  async function onsuccesscheckout(query) {
    const session_id = query.get("checkout_session_id");
    history.replace("/search", "/search");

    setMsg({
      text: "Processing your payment. Please wait...",
      type: "success",
    });
    toggleMsgModal();

    const token = await getUserToken();
    let topup_date = new Date();
    topup_date = topup_date.toLocaleDateString("en-US");
    const session = await checkoutSession(token, { session_id, topup_date });
    if (session) {
      if (session.payment_status === "paid" && session.status === "complete") {
        setMsg({
          text: "Credits has been added to your organization",
          type: "success",
        });
        if (!msgModalOpen) {
          toggleMsgModal();
        }
      }
    } else {
      toggleMsgModal();
    }
    getOrganizationsData();
    getUserCreditLogData();
  }

  async function oncanclecheckout(query) {
    history.replace("/search", "/search");
    setMsg({ text: "Payment was cancelled by you", type: "danger" });
    toggleMsgModal();
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("credit_checkout_success")) {
      onsuccesscheckout(query);
    } else if (query.get("credit_checkout_canceled")) {
      oncanclecheckout(query);
    }
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Search Reports</title>
          </MetaTags>
          <BreadCrumb title="Search Reports" pageTitle="Reports" />
          <ReportSearchSelections />
        </Container>
      </div>
      <Modal isOpen={msgModalOpen} toggle={toggleMsgModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0">
            <div
              className={`alert alert-${msg.type} alert-dismissible fade show m-0`}
              role="alert"
            >
              <div dangerouslySetInnerHTML={{ __html: msg.text }} />
              <button
                type="button"
                className="btn-close"
                onClick={toggleMsgModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}
