import React, { useState, useEffect, useMemo } from "react";
import $ from "jquery";
import { MultiSelect } from "react-multi-select-component";

// const leftFilters = ["Previously Downloaded", "Size", "Purchase Date", "Units Involved", "Unit Number"];

const createHeaderObj = ({ name, colKey, ascSorter, descSorter, optionsSorter, render, ocrData }) =>
  Object.freeze({
    name,
    colKey,
    optionsSorter,
    ocrData,
    ascSorter: ascSorter ? ascSorter(colKey) : undefined,
    descSorter: descSorter ? descSorter(colKey) : undefined,
    render: render === false ? false : true,
  });

export const createHeaderData = (headerData) => {
  headerData = headerData.map((hdata) => createHeaderObj(hdata));

  const headerDataObject = headerData.reduce((acc, curr) => {
    acc[curr.colKey] = curr;
    return acc;
  }, {});

  const initialSortAt = headerData.reduce((acc, curr) => {
    if (!!curr.ascSorter && !!curr.descSorter) acc[curr.colKey] = 2;
    return acc;
  }, {});

  const initialFilterAt = headerData.reduce((acc, curr) => {
    if (!!curr.optionsSorter) acc[curr.colKey] = [];
    return acc;
  }, {});

  return { headerData, headerDataObject, initialSortAt, initialFilterAt };
};

// const DefaultItemRenderer = ({
//   checked,
//   option,
//   onClick,
//   disabled,
// }) => (
//   <div className={`item-renderer ${disabled && "disabled"}`}>
//     <input
//       type="checkbox"
//       onChange={onClick}
//       checked={checked}
//       tabIndex={-1}
//       disabled={disabled}
//     />
//     <span>{option.label}</span>
//   </div>
// );

export function ColumnFilter({ total, index, colKey, options, optionsSorter, filterAt, setFilterAt }) {
  const id = colKey.split(" ").join("_");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    $(document).on("click", (e) => {
      if (e.target.id !== id && e.target.parentElement?.id !== id) {
        const focused = $(":focus");
        const hide = !focused.is("input") && !focused.is("label");
        if (hide) setIsOpen(false);
      }
    });
  }, []);

  const sortedOptions = useMemo(() => {
    if (optionsSorter) return options.sort(optionsSorter);
    return options.sort((a, b) => a.value?.toString().localeCompare(b.value?.toString()));
  }, [colKey, options]);

  return (
    <div className="filter-container">
      <i id={id} className={filterAt[colKey]?.length ? "mdi mdi-filter" : "mdi mdi-filter-outline"} onClick={() => setIsOpen(!isOpen)}></i>
      <MultiSelect
        className={index >= total - 2 ? "multi-select left" : "multi-select right"}
        options={sortedOptions}
        value={filterAt[colKey]}
        onChange={(values) => {
          setFilterAt({ ...filterAt, [colKey]: values });
        }}
        labelledBy="Select"
        selectAllLabel="All"
        disableSearch={false}
        isOpen={isOpen}
        filterOptions={(options, filter) => {
          if (!filter) return options;
          return options.filter((e) => e.value.toLowerCase().search(filter.toLowerCase()) > -1);
        }}
        // itemRenderer={DefaultItemRenderer}
        overrideStrings={{
          allItemsAreSelected: `All ${colKey} Selected`,
          clearSearch: "Clear Search",
          noOptions: `No ${colKey}`,
          search: `Search ${colKey}`,
          selectAll: "Select All",
          selectSomeItems: `Select ${colKey}`,
        }}
      />
    </div>
  );
}

function OCRHeader({ ocrData }) {
  const [ocrPrice, setOcrPrice] = useState(ocrData.price);
  const [ocrLimit, setOcrLimit] = useState(ocrData.file_upload_limit);

  function updatePrice(inputVal) {
    const val = parseFloat(inputVal.slice(1));
    if (isNaN(val)) return setOcrPrice("");
    setOcrPrice(val);
  }

  function updateLimit(inputVal) {
    const val = parseInt(inputVal.slice(1));
    if (isNaN(val)) return setOcrLimit("");
    setOcrLimit(val);
  }

  async function save() {
    ocrData.updateOcrDataCallback({ name: ocrData.name, price: ocrPrice, file_upload_limit: ocrLimit });
  }

  return (
    <div className="d-flex gap-2">
      <input
        className="form-control"
        type="text"
        value={`$${ocrPrice}`}
        onChange={(e) => updatePrice(e.target.value)}
        style={{
          textAlign: "center",
          maxWidth: "4rem",
          minWidth: "57.5px",
        }}
        placeholder="Price"
      />
      <input
        className="form-control"
        type="text"
        value={`#${ocrLimit}`}
        onChange={(e) => updateLimit(e.target.value)}
        style={{
          textAlign: "center",
          maxWidth: "4rem",
          minWidth: "57.5px",
        }}
        placeholder="Price"
      />
      <button className="btn btn-primary" onClick={save}>
        Save
      </button>
    </div>
  );
}

export function FilterAndSortHeaders({ allTableData, filteredTableData, setFilteredTableData, headerData, headerDataObject, initialSortAt, initialFilterAt }) {
  const [filterOrderKey, setFilterOrderKey] = useState([]);
  const [filterAt, setFilterAt] = useState(initialFilterAt);
  const [sortAt, setSortAt] = useState(initialSortAt);

  const sortData = (data) => {
    for (let [key, value] of Object.entries(sortAt)) {
      if (value === 2) continue;
      if (key === "date_of_purchase") {
        data.sort(headerDataObject["id"][["ascSorter", "descSorter"][value]]);
      } else {
        data.sort(headerDataObject[key][["ascSorter", "descSorter"][value]]);
      }
      break;
    }
    return data;
  };

  const getSortClass = (colKey) => ["bx-sort-a-z", "bx-sort-z-a", "bx-sort-z-a"][sortAt[colKey]];

  const updateSortAt = (colKey) => {
    setSortAt({ ...initialSortAt, [colKey]: (sortAt[colKey] + 1) % 3 });
  };

  const filterData = (data) => {
    for (let [key, value] of Object.entries(filterAt)) {
      const values = value.map((e) => e.value);
      if (values?.length === 0) {
        if (filterOrderKey.includes(key)) setFilterOrderKey(filterOrderKey.filter((e) => e !== key));
        continue;
      }

      if (!filterOrderKey.includes(key)) setFilterOrderKey([...filterOrderKey, key]);

      data = data.filter((e) => values.includes(e[key]));
    }

    return data;
  };

  const getFilterOptions = (colKey) => {
    let options = [...allTableData];

    const fIndex = filterOrderKey.findIndex((e) => e === colKey);
    if (fIndex !== -1) {
      for (let i = 1; i <= fIndex; i++) {
        const preKey = filterOrderKey[i - 1];
        const preOptions = filterAt[preKey].map((e) => e.value);

        options = options.filter((e) => preOptions.includes(e[preKey]));
      }
    } else {
      options = [...filteredTableData];
    }

    const toptions = options.reduce((acc, curr) => {
      acc.add(curr[colKey]);
      return acc;
    }, new Set());

    return [...toptions.values()].map((e) => ({ value: e, label: e }));
  };

  useEffect(() => {
    setFilteredTableData(sortData(filterData([...allTableData])));
  }, [sortAt, filterAt, allTableData]);

  const renderOnly = headerData.filter((item) => {
    return item.render !== false;
  });

  return renderOnly.map((item, index) => {
    return (
      <th scope="col" key={index}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <span>{item.name}</span>
          <span className="filters-and-sort-icons">
            {!!item.ascSorter && !!item.descSorter && <i onClick={() => updateSortAt(item.colKey)} className={`bx ${getSortClass(item.colKey)}`}></i>}
            {!!item.optionsSorter && (
              <ColumnFilter
                total={renderOnly.length}
                index={index}
                colKey={item.colKey}
                options={getFilterOptions(item.colKey)}
                setFilterAt={setFilterAt}
                filterAt={filterAt}
                optionsSorter={item.optionsSorter}
              />
            )}
          </span>

          <span className="ocr-header-data">{!!item.ocrData && <OCRHeader ocrData={item.ocrData} />}</span>
        </div>
      </th>
    );
  });
}
