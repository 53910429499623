import React, { useEffect } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";

import HistoryReports from "./HistoryReports";
import TodayReports from "./TodayReports";
import { useDispatch } from "react-redux";
import { getReportData } from "../../store/reportSearch/action";
import { useOrganization } from "../../context/OrganizationContext";

import "./tables.css";

export default function Index() {
  const { getPurchaseHistory } = useOrganization();
  const dispatch = useDispatch();
  useEffect(() => {
    getPurchaseHistory();
    dispatch(getReportData());
  }, []);
  return (
    <div className="page-content">
      <Container fluid>
        <MetaTags>
          <title>Downloaded Reports</title>
        </MetaTags>
        <BreadCrumb title="Downloaded Reports" pageTitle="Reports" />
        <TodayReports />
        <br />
        <HistoryReports />
      </Container>
    </div>
  );
}
