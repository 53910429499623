import React from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ResetPassword from "./ResetPassword";

export default function Index() {
  return (
    <div className="page-content">
      <Container fluid>
        <MetaTags>
          <title>Settings</title>
        </MetaTags>
        <BreadCrumb title="User Settings" pageTitle="Settings" />
        <ResetPassword />
      </Container>
    </div>
  );
}
